import React from "react";
import ReactTooltip from "react-tooltip";
import { useWindowWidth } from "../hooks/useWindowWidth";

const ResourceItem = ({
  type,
  title,
  duration,
  size,
  filePath,
  urlPath,
  videoPreviewImg,
  pdfPreviewImg,
  setShowVideo,
}) => {
  const width = useWindowWidth();

  const handlePlayVideo = () => {
    setShowVideo(true);

    setTimeout(() => {
      const heading = document.querySelector(".modal h4");
      const player = document.querySelector(".video-player");
      const vid = document.querySelector(".video-player-src");

      heading.textContent = title;
      player.pause();
      vid.src = filePath;
      player.load();
      player.play();
    }, 800);
  };

  return (
    <>
      {type === "video" && (
        <div className="media-group">
          <a
            href="/"
            className="media-avatar video"
            data-for="media-avatar"
            data-tip="Watch video"
            onClick={(e) => {
              e.preventDefault();
              handlePlayVideo(e);
            }}
          >
            <div className="cover">
              <div className="btn btn-dk-ghost btn-circle btn-circle-sm">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.5266 12.3653L8 7.3218L8 16.8484L17.5266 12.3653ZM8.93578 5.55423C7.60377 4.84905 6 5.81465 6 7.3218V16.8484C6 18.3145 7.5251 19.2823 8.85159 18.6581L18.3782 14.1749C19.8699 13.473 19.9194 11.3691 18.4624 10.5977L8.93578 5.55423Z"
                    fill="var(--white)"
                  />
                </svg>
              </div>
            </div>
            <img src={videoPreviewImg} alt="" />
          </a>

          <div className="media-content">
            <p className="xs">{title}</p>
            <label className="sm">{duration}</label>
          </div>
          <ReactTooltip className="tooltip-sm" id="media-avatar" place="bottom" disable={width <= 992} />
        </div>
      )}

      {type === "pdf" && (
        <div className="media-group">
          <a
            href="/"
            className="media-avatar pdf"
            data-for="media-avatar"
            data-tip="Open PDF"
            onClick={(e) => {
              e.preventDefault();
              window.open(filePath);
            }}
          >
            <div className="cover">
              <div className="btn btn-dk-ghost btn-circle btn-circle-sm">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.00006 7C7.44778 7 7.00006 7.44772 7.00006 8V16C7.00006 16.5523 7.44778 17 8.00006 17H16.0001C16.5523 17 17.0001 16.5523 17.0001 16V13C17.0001 12.4477 17.4478 12 18.0001 12C18.5523 12 19.0001 12.4477 19.0001 13V16C19.0001 17.6569 17.6569 19 16.0001 19H8.00006C6.34321 19 5.00006 17.6569 5.00006 16L5.00006 8C5.00006 6.34315 6.34321 5 8.00006 5L11.0001 5C11.5523 5 12.0001 5.44772 12.0001 6C12.0001 6.55229 11.5523 7 11.0001 7L8.00006 7Z"
                    fill="var(--white)"
                  />
                  <path
                    d="M12.757 11.2428C12.3664 10.8522 12.3664 10.2191 12.757 9.82854L15.4644 7.12109H14.879C14.3267 7.12109 13.879 6.67338 13.879 6.12109C13.879 5.56881 14.3267 5.12109 14.879 5.12109L17.879 5.12109C18.4313 5.12109 18.879 5.56881 18.879 6.12109V9.12109C18.879 9.67338 18.4313 10.1211 17.879 10.1211C17.3267 10.1211 16.879 9.67338 16.879 9.12109V8.53497L14.1712 11.2428C13.7807 11.6333 13.1475 11.6333 12.757 11.2428Z"
                    fill="var(--white)"
                  />
                </svg>
              </div>
            </div>
            <img src={pdfPreviewImg} alt="" />
          </a>

          <div className="media-content">
            <p className="xs">{title}</p>
            <label className="sm">{size}</label>
            <div className="spacer-12"></div>
            <a
              href={filePath}
              download
              className="btn btn-secondary btn-md btn-sq"
              data-for="pdf-actions"
              data-tip="Download PDF"
              target="_black"
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.2924 15.7071C11.6829 16.0976 12.3161 16.0976 12.7066 15.7071L15.535 12.8787C15.9256 12.4882 15.9256 11.855 15.535 11.4645C15.1445 11.0739 14.5114 11.0739 14.1208 11.4645L12.999 12.5863V4.17154C12.999 3.61925 12.5513 3.17154 11.999 3.17154C11.4467 3.17154 10.999 3.61925 10.999 4.17154L10.999 12.5853L9.87819 11.4645C9.48767 11.0739 8.85451 11.0739 8.46398 11.4645C8.07346 11.855 8.07346 12.4882 8.46398 12.8787L11.2924 15.7071Z"
                  fill="var(--dk_gray)"
                />
                <path
                  d="M5 12C5.55228 12 6 12.4477 6 13V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13V17C20 18.6569 18.6569 20 17 20H7C5.34315 20 4 18.6569 4 17V13C4 12.4477 4.44772 12 5 12Z"
                  fill="var(--dk_gray)"
                />
              </svg>
            </a>
          </div>

          <ReactTooltip className="tooltip-sm" id="media-avatar" place="bottom" disable={width <= 992} />
          <ReactTooltip className="tooltip-sm" id="pdf-actions" place="right" disable={width <= 992} />
        </div>
      )}

      {type === "url" && (
        <div className="media-group">
          <a
            href={urlPath?.includes("http") ? urlPath : "http://" + urlPath}
            className="blue-link"
            target="_black"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        </div>
      )}
    </>
  );
};

export default ResourceItem;
