import { action, thunk, debug } from "easy-peasy";
import { db } from "../firebase";
import sessionModel from "./session";
import userModel from "./user";
import searchModel from "./search";

export const storeModel = {
  session: sessionModel,
  user: userModel,
  search: searchModel,

  setSessionModel: action((state, sessionModel) => {
    state.session = sessionModel;
  }),

  setUserModel: action((state, userModel) => {
    state.user = userModel;
  }),

  setSearchModel: action((state, searchModel) =>{
    state.search = searchModel;
  }),

  createAccount: action((state, { email, username }) => {
    state.user.email = email;
    state.user.username = username;
    state.user.loggedIn = true;
  }),

  signIn: action((state, { username, sid, nameId, isAdmin, adminBoard  }) => {
    state.user.uid = nameId;
    state.user.loggedIn = true;
    state.user.firstName = "Jon";
    state.user.lastName = "Ham";
    state.user.email = "jon@ham.com";
    state.user.username = username;
    state.user.admin = isAdmin;
    state.user.board = adminBoard;
    state.user.sid = sid;
  }),

  signOut: action((state) => {
    state.user.uid = "";
    state.user.loggedIn = false;
    state.user.firstName = "";
    state.user.lastName = "";
    state.user.email = "";
    state.user.username = "";
    state.user.admin = false;
    state.user.board = "";
    state.user.sid = "";
  }),

  acceptTerms: action((state) => {
    state.session.acceptedTerms = true;
  }),

  setStandard: action((state, { board, standard }) => {
    // let boardIdx;

    // if (board === "iaasb") {
    //   boardIdx = 0;
    // } else if (board === "iesba") {
    //   boardIdx = 1;
    // } else if (board === "ipsasb") {
    //   boardIdx = 2;
    // }

    // const boardRef = state.standards[boardIdx];
    // boardRef.versions.push(standard);
  }),
  
  setCurrentSearchResult: action((state, searchResult)=>{
    state.search.currentSearchResult = searchResult;
  }),

  setDownloadedHandbook: action((state, {board, version, standard})=>{
    state.search.downloadedHandbooks.push({ key1: board, key2: version, value:standard });
  }),

  setCurrentBoard: action((state, board) => {
    state.session.currentBoard = board;
  }),
  setCurrentVersion: action((state, version) => {
    state.session.currentVersion = version;
  }),
  setCurrentVersionsEffDate: action((state, VersionsWithEffDate) => {
    state.session.currentVersionsEffDate = VersionsWithEffDate;
  }),
  setCurrentSection: action((state, section) => {
    state.session.currentSection = section;
  }),
  setCurrentSectionId: action((state, sectionId) => {
    state.session.currentSectionId = sectionId;
  }),
  setCurrentStandard: action((state, standard) => {
    state.session.currentStandard = standard;
  }),
  setSectionNames: action((state, sectionNames) => {
    state.session.sectionNames = sectionNames;
  }),

  fetchBoardStandard: thunk(async (actions, { board, version }) => {
    const standard = await db.collection("standards").doc(board).collection(version);
    actions.setStandard(board, standard);
  }),

  getCurrentSection: action((state, id) => {
    const section = state.session.currentStandard?.handbook?.find((section) => section.id === id);
    console.log(debug(state.session.currentStandard?.handbook.find((section) => section.id === id)));
    state.session.currentSection = section.content;
  }),

  saveStandard: action((state, data) => {
    //
  }),

  saveSectionResource: action((state, { section, resources }) => {
    //
  }),

  addSectionResource: action((state, resources) => {
    //
  }),

  addRecentSearch: action((state, data) => {
    const { currentBoard, searchedTerm } = data;
    const boardSearches = state.session.recentSearches[currentBoard];
    if (!boardSearches.includes(searchedTerm)) {
      boardSearches.unshift(searchedTerm);
    }

    if (boardSearches.length >= 7) boardSearches.pop();
  }),

  clearRecentSearches: action((state, currentBoard) => {
    state.session.recentSearches[currentBoard] = [];
  }),
};
