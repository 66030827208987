import React, { useState, useRef, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DotsHorzWhite from "../assets/images/dots-horz-white.png";
import ReactTooltip from "react-tooltip";
import Headroom from "react-headroom";
import { useWindowWidth } from "../hooks/useWindowWidth";
import { ActionBar, BackToTop, ContentPlaceholder } from ".";

const MainContent = ({
  isLoading,
  currentStandard,
  mainContentRef,
  standardContentRef,
  contentScrollRef,
  currentBoard,
  currentVersion,
  currentSection,
  sectionNames,
  definitions,
  showMobileTOC,
  setShowMobileTOC,
  toggleMobileTOC,
  showMobilePanel,
  setShowMobilePanel,
  toggleMobilePanel,
  isEffectiveVersion,
  isPastVersion,
  searchTermRef,
  handleRefreshHandbook,
}) => {
  const history = useHistory();
  const dateFormatOptions = { year: "numeric", month: "short", day: "numeric" };
  const todayDate = new Date();
  const width = useWindowWidth();
  const [zenMode, setZenMode] = useState(false);
  const [fontSize, setFontSize] = useState(0);
  const [showPanel, setShowPanel] = useState(true);
  const [actionBarOverHeader, setActionBarOverHeader] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [showBackToSearch, setShowBackToSearch] = useState(false);
  const actionBarRef = useRef(null);
  const standardHeaderRef = useRef(null);
  const backToTopRef = useRef(null);


  const downHandler = ({ key }) => {
    if (key === 'a') {
      setFontSize(0);
    }
    if (key === 'A') {
      setFontSize(1);
    }
    if (key === 'z') {
      setZenMode(false);
      setShowPanel(true);
    }
    if (key === 'Z') {
      setZenMode(true);
      setShowPanel(false);
    }
    if (key === 'P') {
      setShowPanel(false);
    }
    if (key === 'p') {
      setShowPanel(true);
    }
  };

  useEffect(() => {
    if (searchTermRef && searchTermRef.length > 0) {
      setShowBackToSearch(true);
    }
  }, [searchTermRef]);

  useEffect(() => {


    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, []);

  useEffect(() => {
    const mainRef = standardContentRef.current;

    currentStandard !== null &&
      mainRef.addEventListener("scroll", () => {
        // const { y, height } = standardHeaderRef.current.getBoundingClientRect();
        // setActionBarOverHeader(y <= -height + 40 ? false : true);

        const topY = mainRef.scrollTop;
        setShowBackToTop(topY < 800 ? false : true);
      });

    // actionBarRef.current.addEventListener("click", () => closePanels());
    standardHeaderRef.current.addEventListener("click", () => closePanels());
    standardContentRef.current.addEventListener("click", () => closePanels());

    standardContentRef.current.addEventListener("copy", handleCopyText);

    return () => {
      currentStandard !== null && mainRef.removeEventListener("scroll", {}, false);
      // actionBarRef.current.removeEventListener("click", {}, false);
      standardHeaderRef.current.removeEventListener("click", {}, false);
      standardContentRef.current.removeEventListener("click", {}, false);
    };
  }, [showMobileTOC, showMobilePanel, currentStandard, mainContentRef]);

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 2000);
  }, [currentStandard]);

  const closePanels = () => {
    if (showMobileTOC) setShowMobileTOC(false);
    if (showMobilePanel) setShowMobilePanel(false);
  };

  const toggleZen = useCallback(() => {
    if (zenMode) {
      setZenMode(false);
      setShowPanel(true);
    } else {
      setZenMode(true);
      setShowPanel(false);
    }
  }, [zenMode, setZenMode]);

  const handleFontSizeToggle = () => {
    if (fontSize === 0) {
      setFontSize(1);
    } else if (fontSize === 1) {
      setFontSize(0);
    }
  };




  const togglePanel = useCallback(() => setShowPanel(!showPanel), [showPanel, setShowPanel]);

  const backToTop = () => {
    standardContentRef.current.scroll({ top: 0, behavior: "smooth" });
  };

  const backToSearch = () => {
    history.push(`/search?searchterm=${searchTermRef}`);
  }

  const handleCopyText = async () => {
    const copiedText = await navigator.clipboard.readText();

    let trimmedText = copiedText;
    if (trimmedText.length > 800) {
      trimmedText = copiedText.substring(0, 800) + "\n\n(800 character limit)";
    }

    await navigator.clipboard.writeText(trimmedText);
  };



  return (



    <main
      className={
        "main-content " +
        (zenMode ? "zen " : "") +
        (showPanel ? "side-open " : "side-closed ") +
        (showMobileTOC || showMobilePanel ? "ghosted " : "")
      }
      style={{
        cursor: showMobileTOC || showMobilePanel ? "pointer" : "initial",
        backgroundColor: showMobileTOC || showMobilePanel ? "var(--off_black)" : "var(--white)",
      }}
      ref={mainContentRef}
    >
      <ActionBar
        actionBarRef={actionBarRef}
        actionBarOverHeader={actionBarOverHeader}
        showMobileTOC={showMobileTOC}
        toggleMobileTOC={toggleMobileTOC}
        showMobilePanel={showMobilePanel}
        toggleMobilePanel={toggleMobilePanel}
        showPanel={showPanel}
        togglePanel={togglePanel}
        sectionNames={sectionNames}
        definitions={definitions}
        handleFontSizeToggle={handleFontSizeToggle}
        toggleZen={toggleZen}
        zenMode={zenMode}
        handleRefreshHandbook={handleRefreshHandbook}
      />

      <ReactTooltip
        className="tooltip-sm"
        id="action-bar-font-size"
        place="bottom"
        getContent={() => (fontSize === 1 ? "Decrease font size" : "Increase font size")}
        disable={width <= 992}
      />
      <ReactTooltip
        className="tooltip-sm"
        id="action-bar-refresh"
        place="bottom"
        getContent={() => (fontSize === 1 ? "Refresh" : "Refresh")}
        disable={width <= 992}
      />
      <ReactTooltip
        className="tooltip-sm"
        id="action-bar-zen"
        place="bottom"
        getContent={() => (zenMode ? "Exit zen mode" : "Enter zen mode")}
        disable={width <= 992}
      />
      <ReactTooltip className="tooltip-sm" id="action-bar-tooltips" place="bottom" disable={width <= 992} />

      <header className="standard-header" ref={standardHeaderRef}>
        <div className="title-group inner-container">
          <p className="lead-md">
          <span>{currentVersion}&nbsp;</span>
            {currentBoard !== "iaasb" ? (currentBoard === "iesba" ? (isEffectiveVersion === true ? (
              <span style={{ color: "#bfff00", fontVariant: "small-caps" }}>Currently Effective</span>
            ) : (isPastVersion === true ? (
              <span style={{ color: "#ff66ff", fontVariant: "small-caps" }}>Previously Effective</span>
            ) :
              (
                <span style={{ color: "#ff66ff", fontVariant: "small-caps" }}>Not Yet Effective</span>
              )
            )

            ) : (isEffectiveVersion === true ? (
              <span style={{ color: "#bfff00", fontVariant: "small-caps" }}>Current Version</span>
            ) : (
              <span style={{ color: "#ff66ff", fontVariant: "small-caps" }}>Previous Version</span>
            )
            )

            ) : (<></>)
            }
            {currentBoard === "iaasb" ? (currentVersion.toString() === '2021' ? (<span style={{ color: "var(--white)", fontSize: "14px" }}>(This version has some formatting errors – see General Resources for a full list. A newer version of this handbook is available in PDF format <a href="https://www.iaasb.org/publications/2022-handbook-international-quality-management-auditing-review-other-assurance-and-related-services">here.</a>)</span>)
              : (<span style={{ color: "var(--white)", fontSize: "14px" }}>(A newer version of this handbook is available in PDF format <a href="https://www.iaasb.org/publications/2022-handbook-international-quality-management-auditing-review-other-assurance-and-related-services">here</a>)</span>)) : (<></>)}
          </p>

          <h3>
                <span style={{ textTransform: "uppercase" }}>{currentBoard}</span>
          </h3>

         
          {/* {effectiveDate !== null && (new Date(effectiveDate) > todayDate) && (
            <div>
              <div className="spacer-4" />
              <label className="c-red" style={{fontSize:'24px'}}>
                Effective date: {new Date(effectiveDate).toLocaleDateString("en-US", dateFormatOptions)}
              </label>
            </div>
          )} */}
          <img className="dots bottom show-lg" style={{ right: 0, bottom: "-48px" }} src={DotsHorzWhite} alt="" />
        </div>
      </header>

      <article className={`standard-content ${fontSize === 1 ? "scale-font" : ""}`} ref={standardContentRef}>
        <div className="inner-container">
          {isLoading ? (
            <ContentPlaceholder type="content" />
          ) : (
            <>
              <div
                className="html-content root-el"
                ref={contentScrollRef}
                dangerouslySetInnerHTML={{ __html: currentSection }}
              />

              {/* <div className="standard-footnotes">
                <div className="inner-container">
                  <div className="label-title">Footnotes</div>
                  <div className="spacer-16"></div>
                  <div className="footnote-group">
                    {currentStandard !== null &&
                      currentStandard.footnotes &&
                      currentStandard.footnotes[0].map((footnote, i) => {
                        return (
                          <div id={footnote.id} key={footnote.id + i} className="footnote-item">
                            <p className="footnote-ref">{i + 1}</p>
                            <p className="footnote-content">{footnote.value}</p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div> */}
            </>
          )}
        </div>
      </article>

      <ReactTooltip className="tooltip-sm" id="share-link-tooltip" place="bottom" disable={width <= 992} />

      {showBackToSearch ? <button className="btn btn-primary backToSearchBtnStyle" onClick={backToSearch}>Back To Search</button> : null}

      <BackToTop backToTopRef={backToTopRef} backToTop={backToTop} showBackToTop={showBackToTop} width={width} />

    </main>
  );
};

export default MainContent;
