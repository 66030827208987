import React, { useRef, useState, useEffect } from "react";
import DotsVertGray from "../assets/images/dots-vert-gray.png";
import ReactTooltip from "react-tooltip";
import { motion } from "framer-motion";
import { RouteTransitionWrapper } from "../components";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { useWindowWidth } from "../hooks/useWindowWidth";

const Search = (props) => {
  const history = useHistory();
  const width = useWindowWidth();


  const [isLoading, setIsLoading] = useState(false);
  const [isSelectAll, setSelectAll] = useState(true);

  const searchInputRef = useRef();
  const searchSubmitBtn = useRef();
  const sectionNamesRef = useRef();
  const sectionNamesSelectAllRef = useRef();
  const [hasSearched, setHasSearched] = useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [searchedKeyword, setSearchedKeyword] = useState(null);
  const [isBooleanSearch, setIsBooleanSearch] = useState(false);
  const [definedTerm, setDefinedTerm] = useState(null);
  const [results, setResults] = useState([]);

  const currentUser = useStoreState((state) => state.user);
  const currentStandard = useStoreState((state) => state.session.currentStandard);
  const currentBoard = useStoreState((state) => state.session.currentBoard);
  const currentVersion = useStoreState((state) => state.session.currentVersion);
  const recentSearches = useStoreState((state) => state.session.recentSearches);
  const commonSearches = useStoreState((state) => state.session.commonSearches);
  const addRecentSearch = useStoreActions((actions) => actions.addRecentSearch);
  const clearRecentSearches = useStoreActions((actions) => actions.clearRecentSearches);
  const setCurrentSearchResult = useStoreActions((actions) => actions.setCurrentSearchResult);
  const currentSearchResult = useStoreState((state) => state.search.currentSearchResult);

  const sectionNames = useStoreState((state) => state.session.sectionNames);
  const definitions = currentStandard && currentStandard.glossary && currentStandard.glossary[0]


  const schema = yup.object().shape({
    //
  });

  const setAllSections = () => {
    const selectAllCheckbox = sectionNamesSelectAllRef.current && sectionNamesSelectAllRef.current.querySelectorAll("input:checked");
    const isSelectAllSections = (selectAllCheckbox && selectAllCheckbox.length === 1) ? true: false;
    const sections = sectionNamesRef.current && sectionNamesRef.current.querySelectorAll("input[type=checkbox]");

    sections.forEach((section) => {
      section.checked = isSelectAllSections;
    });
  };

  const resetSelectAll = () => {
    const sectionsAll = sectionNamesRef.current && sectionNamesRef.current.querySelectorAll("input[type=checkbox]");
    const sectionsChecked = sectionNamesRef.current && sectionNamesRef.current.querySelectorAll("input:checked");
    if(sectionsAll && sectionsChecked && (sectionsAll.length > sectionsChecked.length)){
      setSelectAll(false);
    }
  }

  const getSectionNames = () => {
    let sections = {};

    currentBoard &&
      sectionNames &&
      sectionNames.map((section) => {
        return (sections = { ...sections, [section.name]: true });
      });

    return sections;
  };

  const standardSectionsArr = getSectionNames();

  const defaultValues = {
    searchWithin: "searchAllText",
    strictMatchPhrase: true,
    containsRequirements: false,
    shallNotStatements: false,
    booleanSearch: false,
    ...standardSectionsArr,
  };

  const { register, handleSubmit, reset } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const getUrlVars = () => {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
      vars[key] = value;
    });
    return vars;
  };

  useEffect(() => {
    if (currentUser.loggedIn === false) history.push("/");
    if (getUrlVars()["searchterm"]) {
      let searchTerm = getUrlVars()["searchterm"];
      if(searchTerm && searchTerm !== ''){
        searchTerm = searchTerm.toLowerCase().split('%20').join(' ');
        searchInputRef.current.value = searchTerm;
        setSearchedKeyword(searchTerm);
        // formRef.current.dispatchEvent(new Event('submit'));
        setResults(currentSearchResult);
        setHasSearched(true);
      }
    }
    // window.addEventListener('popstate', function (event) {
    //   //const url = this.window.location.href.split('?')[0];
    //   //window.open(url,"_self");
    //   history.push(`standards/${currentBoard}/${currentVersion}`);
    // });
    searchInputRef.current.focus();
  }, []);

  const handleSearchSubmit = (data) => {
    const searchedTerm = searchInputRef.current.value.trim();

    if (searchedTerm === "") {
      searchInputRef.current.focus();
      return;
    }

    handleSearch(searchedTerm, data);
  };

  const handleSearch = (term, data) => {
    setIsLoading(true);

    setSearchedKeyword(term);

    checkDefinedTerm(term);

    setTimeout(() => getSearchResults(term, data), 150);

    setHasSearched(true);
  };

  const handleFillAndSearch = (staticTerm) => {
    searchInputRef.current.value = staticTerm;
    searchSubmitBtn.current.click();
  };

  const checkDefinedTerm = (searchedTerm) => {
    setDefinedTerm(null);
    definitions &&
      definitions.map((term) => {
        const isTerm = term.term.toLowerCase() === searchedTerm.toLowerCase();
        return isTerm ? setDefinedTerm(term) : false;
      });
  };

  const getSearchResults = async (searchedTerm, data) => {
    if (typeof data === "undefined") return;
    const url = process.env.REACT_APP_GET_SEARCH_RESULT;
    const bearerToken = process.env.REACT_APP_GCP_BEARER_TOKEN;
    const { searchWithin, strictMatchPhrase, containsRequirements, shallNotStatements, booleanSearch } = data;
    let searchContentType;
    let isMatchPhrase = true;
    let checkedSections = "";
    // const selectAllCheckbox = sectionNamesSelectAllRef.current && sectionNamesSelectAllRef.current.querySelectorAll("input:checked");
    // const isSelectAllSections = (selectAllCheckbox && selectAllCheckbox.length === 1) ? true: false;

    // if(isSelectAllSections){
    //   let rawCheckedSections = [];
    //   // sectionNames?.map((section, i) => (
    //   //   rawCheckedSections.push(section.containerId)
    //   // ));
    //   for (let i = 0; i < sectionNames.length; i++) {
    //     const section = sectionNames[i];
    //     const id = section.containerId;
    //     rawCheckedSections.push(id);
    //   }
    //   const cleanedCheckedSections = [...new Set(rawCheckedSections)];
    //   checkedSections = cleanedCheckedSections.join(", ").toString();
    // }
    // else{
    //   let rawCheckedSections = [];
    //   const checkedInputs = sectionNamesRef.current && sectionNamesRef.current.querySelectorAll("input:checked");
    //   for (let i = 0; i < checkedInputs.length; i++) {
    //     const input = checkedInputs[i];
    //     const id = input.getAttribute("data-container-id");
    //     rawCheckedSections.push(id);
    //   }
    //   const cleanedCheckedSections = [...new Set(rawCheckedSections)];
    //   checkedSections = cleanedCheckedSections.join(", ").toString();
    // }

    let rawCheckedSections = [];
    const checkedInputs = sectionNamesRef.current && sectionNamesRef.current.querySelectorAll("input:checked");
    for (let i = 0; i < checkedInputs.length; i++) {
      const input = checkedInputs[i];
      const id = input.getAttribute("data-container-id");
      rawCheckedSections.push(id);
    }
    const cleanedCheckedSections = [...new Set(rawCheckedSections)];
    checkedSections = cleanedCheckedSections.join(", ").toString();

    if (booleanSearch) {
      setIsBooleanSearch(true);
      isMatchPhrase = true;
    }
    else
    {
      setIsBooleanSearch(false);   
    }

    if (searchWithin === "searchAllText") {
      searchContentType = "p";
    } else if (searchWithin === "searchAllHeadings") {
      searchContentType = "h";
    }

    const results = await fetch(url, {
      method: "post",
      headers: {
        accept: "application/json",
        authorization: "Bearer " + bearerToken,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        message: searchedTerm,
        contentType: searchContentType,
        shallNot: shallNotStatements,
        requirements: containsRequirements,
        matchPhrase: isMatchPhrase,
        ssb: currentBoard,
        version: currentVersion,
        isBool: booleanSearch,
        sectionFilter: checkedSections,
      }),
      mode: "cors",
    }).then((res) => res.json());

    if (results.length >= 1) {
      const data = { currentBoard, searchedTerm };
      addRecentSearch(data);
    }

    setIsLoading(false);

    let sortedResults = [...results];
    const appearanceResults = sortedResults.sort(function(a, b){return a._source.tagId.replace("p","") - b._source.tagId.replace("p","");});
    setResults(appearanceResults);
    // console.log(JSON.stringify(appearanceResults));
    setCurrentSearchResult(appearanceResults);
  };

  const clearSearch = () => {
    searchInputRef.current.value = "";
    searchInputRef.current.focus();
    setHasSearched(false);
    setIsBooleanSearch(false);
    reset();
  };

  const handleNavigateToResult = (parentId, childId) => {
    //const currentUrl = window.location.href.split("/")[2];
    const url = `standards/${currentBoard}/${currentVersion}?search=${searchedKeyword}&section=${parentId}#${childId}`;
    history.push(url);
    //const win = window.open("https://"+ currentUrl + `/standards/${currentBoard}/${currentVersion}?section=${parentId}#${childId}`, "_blank");
    //win.focus();
  };



  return (
    <RouteTransitionWrapper amount={96}>
      <div className="flex-full-height" style={{ overflow: "auto" }}>
        <header className="header end-xs">
          <button
            onClick={() => props.history.push(`standards/${currentBoard}/${currentVersion}`)}
            className="btn btn-secondary btn-circle btn-circle-md"
            data-for="close"
            data-tip="Close"
            aria-label="close"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                fill="var(--md_gray)"
              />
            </svg>
          </button>
        </header>

        <ReactTooltip className="tooltip-sm" id="close" place="bottom" disable={width <= 992} />

        <div className="spacer-64" />

        <div className="pos-rel h-full">
          <img className="dots left show-lg" src={DotsVertGray} alt="" />
          <img className="dots right bottom show-lg" src={DotsVertGray} alt="" />

          <div className="container pos-rel h-full">
            <div className="row">
              <div className="col-xs-12">
                <form onSubmit={handleSubmit(handleSearchSubmit)} autoComplete="off" noValidate>
                  <div className="search-bar">
                    <div className="flex align-center">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.2992 15.8847C19.4363 13.1361 19.2421 9.16184 16.7163 6.63612C13.9802 3.89994 9.54393 3.89994 6.80775 6.63612C4.07157 9.3723 4.07157 13.8085 6.80775 16.5447C9.27408 19.011 13.1217 19.2543 15.8604 17.2744L17.9501 19.364C18.3406 19.7546 18.9738 19.7546 19.3643 19.364C19.7548 18.9735 19.7548 18.3403 19.3643 17.9498L17.2992 15.8847ZM15.3021 8.05033C17.2573 10.0055 17.2573 13.1754 15.3021 15.1305C13.347 17.0856 10.1771 17.0856 8.22196 15.1305C6.26683 13.1754 6.26683 10.0055 8.22196 8.05033C10.1771 6.0952 13.347 6.0952 15.3021 8.05033Z"
                          fill="var(--md_gray)"
                        />
                      </svg>

                      <div className="spacer-8" />

                      <p className="lead-sm">
                        Search <span style={{ textTransform: "uppercase" }}>{currentBoard}</span> {currentVersion}
                      </p>
                    </div>

                    <div className="search-input-group">
                      <input
                        ref={searchInputRef}
                        className="search-input-lg"
                        type="text"
                        placeholder="Start typing..."
                        name="searchInputRef"
                        // ref={register}
                      />
                      <button type="submit" className="btn btn-secondary btn-lg" ref={searchSubmitBtn} aria-label="submit">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M9.41421 14L16.8286 14C17.3809 14 17.8286 13.5523 17.8286 13V9C17.8286 8.44772 17.3809 8 16.8286 8C16.2763 8 15.8286 8.44771 15.8286 9V12L9.41421 12L10.5355 10.8787C10.9261 10.4882 10.9261 9.85499 10.5355 9.46447C10.145 9.07394 9.51184 9.07394 9.12132 9.46447L6.29289 12.2929C5.90237 12.6834 5.90237 13.3166 6.29289 13.7071L9.12132 16.5355C9.51185 16.9261 10.145 16.9261 10.5355 16.5355C10.9261 16.145 10.9261 15.5118 10.5355 15.1213L9.41421 14Z"
                            fill="var(--md_gray)"
                          />
                        </svg>
                        <div className="spacer-12"></div>
                        Search
                      </button>
                    </div>
                  </div>

                  <div className="spacer-48" />

                  <div className="search-filters">
                    <div style={{ display: toggleFilters ? "block" : "none" }}>
                      <div className="flex space-between align-center">
                        <h6>Advanced filters</h6>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            setToggleFilters(!toggleFilters);
                          }}
                          className="btn btn-secondary btn-md"
                          aria-label="toggle filters"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                              fill="var(--md_gray)"
                            />
                          </svg>
                          <div className="spacer-4"></div>
                          Close
                        </button>
                      </div>

                      <div className="container no-padding">
                        <div className="row">
                          <div className="col-xs-12 col-md-12">
                            <div>
                              <p style={{fontStyle: 'italic',fontFamily: 'Poppins-Regular', fontSize: '14px'}}>The default search is match phrase (strict), which finds paragraphs with the exact phrase as written. </p>
                              <p style={{fontStyle: 'italic',fontFamily: 'Poppins-Regular', fontSize: '14px'}}>To limit, broaden, or define your search, you may choose Boolean search which allows you to combine words using the operators <b style={{fontWeight: 'bold'}}>AND / OR</b>.</p>
                              <p style={{fontStyle: 'italic',fontFamily: 'Poppins-Regular', fontSize: '14px'}}>Where no operator is chosen, Boolean will default to <b style={{fontWeight: 'bold'}}>“OR"</b></p>
                            </div>
                          </div>
                        </div>
                      </div>    

                      <div className="spacer-24"></div>

                      <div className="container no-padding">
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <div>
                              <p className="lead-sm">Search within:</p>
                            </div>
                            <div>
                              <div className="spacer-12" />
                              <div className="">
                                {/* <div className="custom-input-dk">
                                  <input
                                    id="strictMatchPhrase"
                                    type="checkbox"
                                    name="strictMatchPhrase"
                                    ref={register}
                                  />
                                  <label htmlFor="strictMatchPhrase" tabIndex="0">
                                    Match phrase (strict)
                                  </label>
                                </div> */}

                                { currentBoard === 'iesba' &&
                                  <>
                                  <div className="spacer-12" />
                                  <div className="custom-input-dk">
                                    <input
                                        id="containsRequirements"
                                        type="checkbox"
                                        name="containsRequirements"
                                        ref={register}
                                    />
                                    <label htmlFor="containsRequirements" tabIndex="0">
                                      Contains requirements
                                    </label>
                                  </div>

                                  <div className="spacer-12" />

                                  <div className="custom-input-dk">
                                  <input
                                  id="shallNotStatements"
                                  type="checkbox"
                                  name="shallNotStatements"
                                  ref={register}
                                  />
                                  <label htmlFor="shallNotStatements" tabIndex="0">
                                  Only show explicit prohibitions
                                  </label>
                                  </div>
                                    </>
                                }

                                <div className="spacer-12" />

                                <div className="custom-input-dk">
                                  <input id="booleanSearch" type="checkbox" name="booleanSearch" ref={register} />
                                  <label htmlFor="booleanSearch" tabIndex="0">
                                    Use strict search with Boolean operators
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="spacer-48"></div>
                          </div>
                          {currentBoard && sectionNames && (
                            <div className="col-xs-12 col-md-6">
                              <div>
                                <p className="lead-sm">By section:</p>

                                <div className="spacer-12" />

                                <div className="custom-input-dk" ref={sectionNamesSelectAllRef}>
                                  <input
                                          id="SelectAll"
                                          type="checkbox"
                                          name="SelectAll"
                                          checked={isSelectAll}
                                          ref={register}
                                          onClick={() => {setSelectAll(!isSelectAll);setAllSections();}}
                                  />
                                  <label htmlFor={"SelectAll"} tabIndex="0">
                                          Select All
                                  </label>
                                </div>
                                  
                                <div className="spacer-12" />
                                <div ref={sectionNamesRef}>
                                  {sectionNames?.filter(sectionName => (currentBoard === 'iesba' &&  sectionName?.name.startsWith('PART ')) || (currentBoard === 'iaasb' && !sectionName?.name.startsWith('VOLUME')) || (currentBoard === 'ipsasb' && !sectionName?.name.startsWith('VOLUME'))).map((section, i) => (
                                    <div key={section.key + i}>
                                      <div className="custom-input-dk">
                                        <input
                                          id={section.name}
                                          type="checkbox"
                                          data-container-id={section.containerId}
                                          name={section.name}
                                          ref={register}
                                          onClick={() => {resetSelectAll();}}
                                        />
                                        <label htmlFor={section.name} tabIndex="0">
                                          {section.name}
                                        </label>
                                      </div>
                                      <div className="spacer-12" />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="spacer-48"></div>
                    </div>

                    <div className="flex space-between align-center">
                      <div>
                        {hasSearched && !isLoading && (
                          <motion.div
                            initial={{
                              opacity: 0,
                            }}
                            exit={{
                              opacity: 0,
                            }}
                            animate={{
                              opacity: 1,
                            }}
                            transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                          >
                            <div className="flex align-center">
                              <label className="c-dk_gray font-bold">
                                <span className="c-blue">{results.length >= 1 ? results.length : "0"}</span> Results for
                                <span className="font-bold-italic"> “{searchedKeyword}”</span>
                              </label>
                              <div className="spacer-32"></div>
                              <button className="btn btn-secondary btn-sm" onClick={clearSearch} aria-label="clear search">
                                Clear search
                              </button>
                            </div>
                          </motion.div>
                        )}
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setToggleFilters(!toggleFilters);
                        }}
                        className="btn btn-secondary btn-md"
                        aria-label="toggle filters"
                      >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.17071 9H5C4.44772 9 4 8.55228 4 8C4 7.44772 4.44772 7 5 7H6.17071C6.58254 5.83481 7.69378 5 9 5C10.3062 5 11.4175 5.83481 11.8293 7H19C19.5523 7 20 7.44772 20 8C20 8.55228 19.5523 9 19 9H11.8293C11.4175 10.1652 10.3062 11 9 11C7.69378 11 6.58254 10.1652 6.17071 9ZM10 8C10 8.55228 9.55228 9 9 9C8.44772 9 8 8.55228 8 8C8 7.44772 8.44772 7 9 7C9.55228 7 10 7.44772 10 8Z"
                            fill="var(--md_gray)"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.1707 17H5C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15H11.1707C11.5825 13.8348 12.6938 13 14 13C15.3062 13 16.4175 13.8348 16.8293 15H19C19.5523 15 20 15.4477 20 16C20 16.5523 19.5523 17 19 17H16.8293C16.4175 18.1652 15.3062 19 14 19C12.6938 19 11.5825 18.1652 11.1707 17ZM15 16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15C14.5523 15 15 15.4477 15 16Z"
                            fill="var(--md_gray)"
                          />
                        </svg>
                        <div className="spacer-4"></div>
                        Advanced filters
                      </button>
                    </div>
                  </div>
                </form>
                <div className="spacer-48" />

                {!hasSearched && (
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: 48,
                    }}
                    exit={{
                      opacity: 0,
                      y: 48,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                    }}
                    transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                  >
                    <div className="row">
                      {recentSearches[currentBoard] && recentSearches[currentBoard]?.length >= 1 && (
                        <>
                          <div className="col-xs-12 col-sm-6">
                            <div className="flex space-between align-center">
                              <h6>Recent searches</h6>
                              <button
                                className="btn btn-secondary btn-sm"
                                onClick={() => clearRecentSearches(currentBoard)}
                                aria-label="clear recent searches"
                              >
                                Clear
                              </button>
                              <div className="hide-xs show-sm"></div>
                            </div>

                            <div className="spacer-24" />

                            <ul className="searches-list">
                              {recentSearches[currentBoard] &&
                                recentSearches[currentBoard]?.map((searchTerm, i) => (
                                  <li key={searchTerm + i}>
                                    <button className="blue-link" onClick={() => handleFillAndSearch(searchTerm)} aria-label="search">
                                      {searchTerm}
                                    </button>
                                  </li>
                                ))}
                            </ul>
                          </div>

                          <div className="spacer-48 show-xs hide-sm"></div>
                        </>
                      )}

                      {commonSearches[currentBoard]?.length >= 1 && (
                        <div
                          className={
                            recentSearches[currentBoard]?.length >= 1 ? "col-xs-12 col-sm-6" : "col-xs-12 col-sm-12"
                          }
                        >
                          <h6 style={{ display: "flex", height: 32, alignItems: "center" }}>Common searches</h6>

                          <div className="spacer-24" />

                          <div className="row">
                            <div className="col-xs-12 col-sm-12">
                              <ul className="searches-list">
                                {commonSearches[currentBoard] &&
                                  commonSearches[currentBoard]?.map((searchTerm, i) => (
                                    <li key={searchTerm + i}>
                                      <button className="blue-link" onClick={() => handleFillAndSearch(searchTerm)} aria-label="search">
                                        {searchTerm}
                                      </button>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="spacer-48"></div>
                  </motion.div>
                )}

                {hasSearched && (
                  <motion.div
                    initial={{
                      opacity: 0,
                      y: 48,
                    }}
                    exit={{
                      opacity: 0,
                      y: 48,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                    }}
                    transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                  >
                    {!isLoading && definedTerm !== null && (
                      <>
                        <div>
                          <h6>Defined term</h6>
                          <div className="spacer-12"></div>
                          <div className="flex defined-term search-result" style={{ marginLeft: '-105px'}}>
                            <div>
                              <p className="result-title font-bold sm c-off_black">{definedTerm.term}</p>
                              <div className="spacer-12" />
                              <label className="sm c-dk_gray">{definedTerm.definition}</label>
                            </div>
                          </div>
                        </div>
                        <div className="spacer-24"></div>
                      </>
                    )}

                    {results.length >= 1 && (
                      <div>
                        <div className="flex space-between align-center">
                          <h6>Results</h6>
                        </div>

                        <div className="spacer-12"></div>

                        {results.map((result) => (
                          <button
                            key={result._id}
                            onClick={() => handleNavigateToResult(result._source.tagParent, result._source.tagId)}
                            className="flex search-result align-center"
                            aria-label="go to result"
                          >
                            <div className="flex-1">
                              <p className="result-title font-bold sm c-off_black" style={{ textAlign: 'left'}}>
                                <span className="c-md_gray">{result._source.breadcrumb}</span>
                              </p>
                              <div className="spacer-16"></div>
                              <div className="flex indented align-baseline">
                                <p className="xs c-off_black font-bold">{result._source.paragraphName}</p>
                                <label
                                  className="sm c-dk_gray cur-pointer result-text"
                                  dangerouslySetInnerHTML={{
                                    __html: result._source.tagContent
                                        .replace(/(.{500})..+/, "$1&hellip;")
                                        .replace( new RegExp(`\\b(${searchedKeyword})\\b`, 'ig'), '<em>$1</em>')
                                  }}
                                />
                              </div>
                            </div>
                            <div className="spacer-32"></div>
                            <div className="btn btn-secondary btn-circle btn-circle-md">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.1213 8.46446C11.5118 8.07394 12.145 8.07394 12.5355 8.46446L15.3639 11.2929C15.7544 11.6834 15.7544 12.3166 15.3639 12.7071L12.5355 15.5355C12.145 15.9261 11.5118 15.9261 11.1213 15.5355C10.7307 15.145 10.7307 14.5118 11.1213 14.1213L13.2426 12L11.1213 9.87868C10.7307 9.48815 10.7307 8.85499 11.1213 8.46446Z"
                                  fill="var(--md_gray)"
                                />
                              </svg>
                            </div>
                          </button>
                        ))}
                      </div>
                    )}

                    {!isLoading && results.length === 0 && <h6>No results were found</h6>}

                    {isLoading && (
                      <div className="loader-full-bg">
                        <div className="loader" />
                      </div>
                    )}

                    <div className="spacer-48"></div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </RouteTransitionWrapper>
  );
};

export default Search;
