import React, { useState, useEffect,useRef  } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AnimatePresence, motion } from "framer-motion";
import { ResourceItem } from ".";
import { useDrag, useDrop } from "react-dnd";

const pdfjs = require("pdfjs-dist");
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

const alertSVG = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 4.83333C7 4.3731 7.44772 4 8 4C8.55228 4 9 4.3731 9 4.83333V8.16667C9 8.6269 8.55228 9 8 9C7.44772 9 7 8.6269 7 8.16667V4.83333Z" fill="var(--red)"/>
    <path d="M7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11Z" fill="var(--red)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="var(--red)"/>
  </svg>`;

const ResourceInputItem = ({
  existingResource,
  index,
  setResourceItem,
  removeResourceItem,
  setShowVideo,
  updateValidationItem,
  moveResourceInputItem,
}) => {
  const [isExistingResource, setIsExistingResource] = useState();
  const [resourceType, setResourceType] = useState();
  const [resourceTitle, setResourceTitle] = useState();
  const [resourceVideoFile, setResourceVideoFile] = useState(null);
  const [resourceVideoPreviewImg, setResourceVideoPreviewImg] = useState(null);
  const [resourcePdfPreviewImg, setResourcePdfPreviewImg] = useState(null);
  const [resourcePdfFile, setResourcePdfFile] = useState(null);
  const [resourceUrl, setResourceUrl] = useState();
  const [resourceId, setResourceId] = useState();
  const [resourceIndex, setResourceIndex] = useState(index);
  const [previewMode, setPreviewMode] = useState(false);

  const [videoDuration, setVideoDuration] = useState();
  const [fileName, setFileName] = useState();
  const [fileSize, setFileSize] = useState();
  const [filePath, setFilePath] = useState();

  const [disablePreviewButton, setDisablePreviewButton] = useState(true);

  const fileInfo = [];

  const schema = yup.object().shape({
    resourceType: yup.string().required("Type is required"),
    resourceTitle: yup.string().required("Title is required"),
    resourceVideoFile: yup.mixed().test("fileFormat", "The file must be .mp4 or .mov", (value) => {
      if (value.length === 0) return;
      const isVideoFormat = (value[0].type === ("video/mp4") || value[0].type === ("video/quicktime"));
      if (!isVideoFormat) return;
      setResourceVideoFile(value[0]);
      return isVideoFormat;
    }),
    resourcePdfFile: yup.mixed().test("fileFormat", "The file must be .pdf", (value) => {
      if (value.length === 0) return;
      const isPdf = value[0].type === "application/pdf";
      if (!isPdf) return;
      setResourcePdfFile(value[0]);
      return isPdf;
    }),
    resourceUrl: yup.string().url().required("URL is required"),
  });

  const { register, errors, getValues, setValue, formState, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (Object.entries(existingResource).length !== 0) {
      if (existingResource.resourceTitle) setIsExistingResource(true);

      if (existingResource.resourceType === "video") {
        setResourceId(existingResource.resourceId);
        setResourceIndex(existingResource.resourceIndex);
        setResourceType(existingResource.resourceType);
        setResourceTitle(existingResource.resourceTitle);
        setResourceVideoFile({ name: existingResource.fileName });
        setResourceVideoPreviewImg(existingResource.resourceVideoPreviewImg);
        setVideoDuration(existingResource.videoDuration);
        setFileName(existingResource.fileName);
        setFileSize(existingResource.fileSize);
        setFilePath(existingResource.filePath);
      } else if (existingResource.resourceType === "pdf") {
        setResourceId(existingResource.resourceId);
        setResourceIndex(existingResource.resourceIndex);
        setResourceType(existingResource.resourceType);
        setResourceTitle(existingResource.resourceTitle);
        setResourcePdfFile({ name: existingResource.fileName });
        setResourcePdfPreviewImg(existingResource.resourcePdfPreviewImg);
        setFileName(existingResource.fileName);
        setFileSize(existingResource.fileSize);
        setFilePath(existingResource.filePath);
      } else if (existingResource.resourceType === "url") {
        setResourceId(existingResource.resourceId);
        setResourceIndex(existingResource.resourceIndex);
        setResourceType(existingResource.resourceType);
        setResourceTitle(existingResource.resourceTitle);
        setResourceUrl(existingResource.resourceUrl);
      }
    } else {
      setResourceType("pdf");
    }
  }, []);

  
  useEffect(() => {
    handleUpdateResourceItem();
  }, [formState]);

  const handleUpdateResourceItem = async () => {
    const data = getValues();

    const isVideo = isExistingResource
      ? resourceType === "video" &&
        fileSize !== (undefined || "") &&
        videoDuration !== (undefined || "") &&
        data.resourceTitle !== ""
      : resourceType === "video" &&
        fileSize !== (undefined || "") &&
        videoDuration !== (undefined || "") &&
        data.resourceTitle !== "" &&
        data.resourceVideoFile &&
        data.resourceVideoFile.length !== 0;

    const isPdf = isExistingResource
      ? resourceType === "pdf" && fileSize !== (undefined || "") && data.resourceTitle !== ""
      : resourceType === "pdf" &&
        fileSize !== (undefined || "") &&
        data.resourceTitle !== "" &&
        data.resourcePdfFile &&
        data.resourcePdfFile.length !== 0;

    const isUrl = resourceType === "url" && data.resourceTitle !== "" && data.resourceUrl !== "";

    if (isVideo) {
      setResourceItem(
        { ...data, resourceId, filePath, fileName, fileSize, videoDuration, resourceVideoPreviewImg },
        index
      );
      setDisablePreviewButton(false);
      resourceId && updateValidationItem(resourceId, true);
    } else if (isPdf) {
      setResourceItem({ ...data, resourceId, filePath, fileName, fileSize, resourcePdfPreviewImg }, index);
      setDisablePreviewButton(false);
      resourceId && updateValidationItem(resourceId, true);
    } else if (isUrl) {
      setResourceItem({ ...data, resourceId }, index);
      setDisablePreviewButton(false);
      resourceId && updateValidationItem(resourceId, true);
    } else {
      setDisablePreviewButton(true);
      resourceId && updateValidationItem(resourceId, false);
    }
  };

  const handleTypeUpdate = (e) => {
    setResourceType(e.target.value);
  };

  const setFileInfo = (e) => {
    const files = e.target.files;
    fileInfo.push(files[0]);

    if (resourceType === "video") {
      const fileReader = new FileReader();
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        const name = fileInfo[fileInfo.length - 1].name;
        const size = fileInfo[fileInfo.length - 1].size;
        const duration = Math.floor(video.duration);
        setFilePath(window.URL.createObjectURL(files[0]));
        fileInfo[fileInfo.length - 1].duration = duration;

        let hours = Math.floor(duration / 60 / 60);
        let minutes = Math.floor(duration / 60) - hours * 60;
        let seconds = duration % 60;
        let videoDuration;

        if (minutes === 0) {
          videoDuration = seconds + " secs";
        } else {
          if (seconds <= 9) seconds = "0" + seconds;
          videoDuration = minutes + ":" + seconds + " mins";
        }

        setVideoDuration(videoDuration);
        setFileName(name);
        setFileSize(formatBytes(size));
      };

      fileReader.onload = () => {
        const blob = new Blob([fileReader.result], { type: files[0].type });
        const url = URL.createObjectURL(blob);
        const video = document.createElement("video");

        const timeupdate = () => {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
            video.pause();
          }
        };

        video.addEventListener("loadeddata", () => {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate);
          }
        });

        const snapImage = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
          const image = canvas.toDataURL();
          setResourceVideoPreviewImg(image);
        };

        video.addEventListener("timeupdate", timeupdate);
        video.preload = "metadata";
        video.src = url;
        video.muted = true;
        video.playsInline = true;
        video.play();
        setTimeout(() => video.pause(), 1000);
      };

      fileReader.readAsArrayBuffer(files[0]);
      video.src = URL.createObjectURL(files[0]);
    } else if (resourceType === "pdf") {
      const fileReader = new FileReader();
      const name = fileInfo[fileInfo.length - 1].name;
      const size = fileInfo[fileInfo.length - 1].size;
      setFilePath(window.URL.createObjectURL(files[0]));
      setFileName(name);
      setFileSize(formatBytes(size));

      fileReader.onload = () => {
        const pdfData = new Uint8Array(fileReader.result);
        const loadingTask = pdfjs.getDocument({ data: pdfData });

        loadingTask.promise.then(
          (pdf) => {
            const pageNumber = 1;

            pdf.getPage(pageNumber).then((page) => {
              const scale = 1;
              const viewport = page.getViewport({ scale: scale });

              const canvas = document.createElement("canvas");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const context = canvas.getContext("2d");

              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };

              const renderTask = page.render(renderContext);

              renderTask.promise.then(() => {
                context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
                const image = canvas.toDataURL();
                setResourcePdfPreviewImg(image);
              });
            });
          },
          (reason) => console.error(reason)
        );
      };

      fileReader.readAsArrayBuffer(files[0]);
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
      accept: 'INPUTRESOURCE',
      collect(monitor) {
          return {
              handlerId: monitor.getHandlerId(),
          };
      },
      hover(item, monitor) {
          if (!ref.current) {
              return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
              return;
          }
          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();
          // Get vertical middle
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          // Determine mouse position
          const clientOffset = monitor.getClientOffset();
          // Get pixels to the top
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%
          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
              return;
          }
          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
              return;
          }
          // Time to actually perform the action
          moveResourceInputItem(dragIndex, hoverIndex);
          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex;
      },
  });

  const [{ isDragging }, drag] = useDrag({
    item: () => {
      return { resourceId, index };
    },
    type: 'INPUTRESOURCE',
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(drop(ref));
  return (
    <form ref={ref} style={{  opacity }} data-handler-id={handlerId} autoComplete="off" noValidate>
      <div className="resource-input-item">
        <div style={{ display: previewMode ? "block" : "none" }}>
          <ResourceItem
            type={resourceType}
            title={resourceTitle}
            duration={videoDuration}
            size={fileSize}
            filePath={filePath}
            urlPath={resourceUrl}
            videoPreviewImg={resourceVideoPreviewImg}
            pdfPreviewImg={resourcePdfPreviewImg}
            setShowVideo={setShowVideo}
          />
        </div>

        <div style={{ display: !previewMode ? "block" : "none" }}>
          <div className="flex align-center">
            <label className="sm c-md_gray" htmlFor="resourceType">
              Type
            </label>

            <div className="select-dropdown">
              <select
                ref={register}
                defaultValue={existingResource.resourceType || resourceType}
                onChange={(e) => handleTypeUpdate(e)}
                className="nav-tab secondary sm"
                name="resourceType"
              >
                <option value="pdf">PDF</option>
                <option value="url">URL</option>
                <option value="video">Video</option>
              </select>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ right: 4 }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.7055 14.2055C12.315 14.596 11.6818 14.596 11.2913 14.2055L8.46288 11.377C8.07235 10.9865 8.07235 10.3533 8.46288 9.96282C8.8534 9.57229 9.48657 9.57229 9.87709 9.96282L11.9984 12.0841L14.1197 9.96282C14.5103 9.57229 15.1434 9.57229 15.5339 9.96282C15.9245 10.3533 15.9245 10.9865 15.5339 11.377L12.7055 14.2055Z"
                  fill="var(--md_gray)"
                />
              </svg>
            </div>
          </div>

          <div className="spacer-8" />

          <div className="flex align-center">
            <label className="sm c-md_gray" htmlFor="resourceTitle">
              Title
            </label>

            <input
              ref={register}
              type="text"
              name="resourceTitle"
              className={`form-input sm secondary `}
              placeholder="Display title..."
              defaultValue={resourceTitle}
              onChange={(e) => {
                handleUpdateResourceItem();
                setValue("resourceTitle", e.target.value);
                setResourceTitle(e.target.value);
              }}
              required
            />
          </div>

          <div className="spacer-8" />

          {resourceType === "video" && (
            <div className="flex align-center">
              <label className="sm c-md_gray" htmlFor="resourceVideoFile">
                Video file
              </label>

              <div
                className={`file-upload resource-file ${resourceVideoFile && resourceVideoFile.name && "has-file"} ${
                  errors.resourceVideoFile && "has-error"
                }`}
              >
                <div className="flex align-center">
                  <div>
                    <AnimatePresence exitBeforeEnter>
                      {resourceVideoFile && resourceVideoFile.name ? (
                        <motion.div
                          initial={{
                            opacity: 0,
                            y: 0,
                          }}
                          exit={{
                            opacity: 0,
                            y: 24,
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                          }}
                          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                        >
                          <button
                            className="btn btn-sm btn-warning btn-sq btn-circle remove-resource-file"
                            onClick={() => {
                              setResourceVideoFile(null);
                              setFileSize("");
                              reset({ resourceType: "video", resourceVideoFile: null });
                            }}
                            aria-label="remove resource"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                                fill="var(--md_gray)"
                              />
                            </svg>
                          </button>
                          <div className="flex align-center">
                            <div className="btn btn-secondary btn-circle btn-circle-sm">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M17.5266 12.3653L8 7.3218L8 16.8484L17.5266 12.3653ZM8.93578 5.55423C7.60377 4.84905 6 5.81465 6 7.3218V16.8484C6 18.3145 7.5251 19.2823 8.85159 18.6581L18.3782 14.1749C19.8699 13.473 19.9194 11.3691 18.4624 10.5977L8.93578 5.55423Z"
                                  fill="var(--dk_gray)"
                                />
                              </svg>
                            </div>
                            <div className="spacer-8" />
                            <div style={{ textAlign: "left" }}>
                              <p className="xs c-md_gray file-name">{fileName}</p>
                              <div className="spacer-4" />
                              <p className="xs c-md_gray op-65">{fileSize}</p>
                            </div>
                          </div>
                        </motion.div>
                      ) : (
                        <motion.div
                          initial={{
                            opacity: 0,
                            y: 0,
                          }}
                          exit={{
                            opacity: 0,
                            y: 24,
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                          }}
                          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                        >
                          <p className="xs c-dk_gray">Drag or select a video file</p>
                          <div className="spacer-4" />
                          <p className="xs c-dk_gray op-65">.mp4 or .mov</p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <input
                  ref={register}
                  className="full"
                  type="file"
                  name="resourceVideoFile"
                  required
                  accept="video/*"
                  onChange={(e) => {
                    if (e.target.files[0]?.type === "video/mp4" || e.target.files[0]?.type === "video/quicktime") {
                      setFileInfo(e);
                    } else {
                      e.target.value = null;
                    }
                  }}
                />
              </div>
              {errors.resourceVideoFile && (
                <div className="flex flex-column align-bottom">
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{ __html: alertSVG + errors.resourceVideoFile.message }}
                  />
                </div>
              )}
            </div>
          )}

          {resourceType === "pdf" && (
            <div className="flex align-center">
              <label className="sm c-md_gray" htmlFor="resourcePdfFile">
                PDF file
              </label>

              <div
                className={`file-upload resource-file ${resourcePdfFile && "has-file"} ${
                  errors.resourcePdfFile && "has-error"
                }`}
              >
                <div className="flex align-center">
                  <div>
                    <AnimatePresence exitBeforeEnter>
                      {resourcePdfFile && resourcePdfFile.name ? (
                        <motion.div
                          initial={{
                            opacity: 0,
                            y: 0,
                          }}
                          exit={{
                            opacity: 0,
                            y: 24,
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                          }}
                          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                        >
                          <button
                            className="btn btn-sm btn-warning btn-sq btn-circle remove-resource-file"
                            onClick={() => {
                              setResourcePdfFile(null);
                              setFileSize("");
                              reset({ resourceType: "pdf", resourcePdfFile: null });
                            }}
                            aria-label="remove resource"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                                fill="var(--md_gray)"
                              />
                            </svg>
                          </button>
                          <div className="flex align-center">
                            <div className="btn btn-secondary btn-circle btn-circle-sm">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8 2C5.79086 2 4 3.79086 4 6V18C4 20.2091 5.79086 22 8 22H16C18.2091 22 20 20.2091 20 18V10.2426C20 9.18177 19.5786 8.16436 18.8284 7.41421L14.5858 3.17157C13.8356 2.42143 12.8182 2 11.7574 2H8ZM6 6C6 4.89543 6.89543 4 8 4H11V8C11 9.65685 12.3431 11 14 11H18V18C18 19.1046 17.1046 20 16 20H8C6.89543 20 6 19.1046 6 18V6ZM17.5671 9C17.5197 8.94022 17.4687 8.88291 17.4142 8.82843L13.1716 4.58579C13.1171 4.5313 13.0598 4.48028 13 4.43288V8C13 8.55228 13.4477 9 14 9H17.5671Z"
                                  fill="var(--md_gray)"
                                />
                              </svg>
                            </div>
                            <div className="spacer-8" />
                            <div style={{ textAlign: "left" }}>
                              <p className="xs c-md_gray file-name">{fileName}</p>
                              <div className="spacer-4" />
                              <p className="xs c-md_gray op-65">{fileSize}</p>
                            </div>
                          </div>
                        </motion.div>
                      ) : (
                        <motion.div
                          initial={{
                            opacity: 0,
                            y: 0,
                          }}
                          exit={{
                            opacity: 0,
                            y: 24,
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                          }}
                          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                        >
                          <p className="xs c-md_gray">Drag or select a PDF file</p>
                          <div className="spacer-4" />
                          <p className="xs c-md_gray op-65">.pdf</p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
                <input
                  ref={register}
                  className="full"
                  type="file"
                  name="resourcePdfFile"
                  required
                  accept="application/pdf"
                  onChange={(e) => {
                    if (e.target.files[0]?.type === "application/pdf") {
                      setFileInfo(e);
                    } else {
                      e.target.value = null;
                    }
                  }}
                />
              </div>
              {errors.resourcePdfFile && (
                <div className="flex flex-column align-bottom">
                  <div
                    className="error"
                    dangerouslySetInnerHTML={{ __html: alertSVG + errors.resourcePdfFile.message }}
                  />
                </div>
              )}
            </div>
          )}

          {resourceType === "url" && (
            <div className="flex align-center">
              <label className="sm c-md_gray" htmlFor="resourceUrl">
                URL
              </label>

              <input
                ref={register}
                type="text"
                name="resourceUrl"
                className={`form-input sm secondary `}
                placeholder="Link URL..."
                required
                defaultValue={resourceUrl}
                onChange={(e) => {
                  handleUpdateResourceItem();
                  setValue("resourceUrl", e.target.value);
                  setResourceUrl(e.target.value);
                }}
              />
            </div>
          )}
        </div>

        <div className="spacer-8" />

        <div className="flex align-center">
          <div className="spacer-48" style={{ height: 32, minHeight: 32 }} />
          <button
            className="btn btn-md btn-secondary flex-1"
            onClick={(e) => {
              e.preventDefault();
              setPreviewMode(!previewMode);
            }}
            disabled={disablePreviewButton}
            aria-label="set preview"
          >
            {previewMode ? "Close" : "Preview"}
          </button>

          {/* {index !== 0 && ( */}
          <>
            <div className="spacer-8" />
            <button
              className="btn btn-md btn-warning flex-1"
              onClick={(e) => {
                e.preventDefault();
                removeResourceItem(resourceId);
              }}
              aria-label="remove"
            >
              Remove
            </button>
          </>
          {/* )} */}
        </div>
      </div>
      <br></br>
    </form>
  );
};

export default React.memo(ResourceInputItem);
