import React, { useState } from "react";
import DotsHorzGray from "../assets/images/dots-horz-gray.png";
import { motion } from "framer-motion";
import { ContentPlaceholder, Accordion, Resources } from ".";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Offline, Online } from "react-detect-offline";

const SidePanel = ({
  isLoading,
  definitions,
  resources,
  getBoardResources,
  setBoardResources,
  showMobilePanel,
  setShowVideo,
}) => {
 
  const [expanded, setExpanded] = useState(null);
  const [activeTab, setActiveTab] = useState(1);


  

  return (
    <aside
      className={"side-content " + (showMobilePanel ? "side-open-mobile " : "side-closed-mobile ")}
      aria-label="definitions"
    >
      {/* <Online>{setActiveTab(1)}</Online>
      <Offline>{setActiveTab(0)}</Offline> */}
      <div className="nav-tabs">
        <button
          className={`nav-tab sm ${activeTab === 0 ? "primary" : "secondary"}`}
          onClick={() => setActiveTab(0)}
          aria-label="definitions"
        >
          Definitions
        </button>
        <button
          className={`nav-tab sm ${activeTab === 1 ? "primary" : "secondary"}`}
          onClick={() => setActiveTab(1)}
          disabled={!resources}
          aria-label="resources"
        >
          Resources
        </button>
      </div>


      {activeTab === 0 && (
        <motion.div
          className="tab-content"
          initial={{
            opacity: 0,
            y: 12,
          }}
          exit={{
            opacity: 0,
            y: 12,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        >
          {isLoading ? (
            <>
              <div className="spacer-16" />
              <ContentPlaceholder type="panel" />
            </>
          ) : (
            <div className="accordion-group">
              {definitions &&
                definitions.map((definition, i) => (
                  <Accordion key={i} id={i} definition={definition} expanded={expanded} setExpanded={setExpanded} />
                ))}
            </div>
          )}
        </motion.div>
      )}

      {activeTab === 1 && (
        <motion.div
          className="tab-content resource-list"
          initial={{
            opacity: 0,
            y: 12,
          }}
          exit={{
            opacity: 0,
            y: 12,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
        >
          {isLoading ? (
            <>
              <div className="spacer-16" />
              <div style={{ padding: "0 24px" }}>
                <ContentPlaceholder type="panel" />
              </div>
            </>
          ) : (
            <DndProvider backend={HTML5Backend}>
              <Resources
                resourceList={resources}
                getBoardResources={getBoardResources}
                setBoardResources={setBoardResources}
                setShowVideo={setShowVideo}
              />
            </DndProvider>
          )}
        </motion.div>
      )}
      



      <img className="dots right bottom show-lg" src={DotsHorzGray} alt="" />
    </aside>
  );
};

export default SidePanel;
