import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { Home, Upload, Standards, Search } from "./screens";
import { AnimatedRoutes } from "./components";


const PrivateRoute = ({ children, ...rest }) => {
  const currentUser = useStoreState((state) => state.user);
  return (
    <Route
      {...rest}
      render={() => currentUser.sid ? children : <Redirect to='/' />
      }
    />
  )
}

const AdminRoute = ({ children, ...rest }) => {
  const currentUser = useStoreState((state) => state.user);
  return (
      <Route
          {...rest}
          render={() => currentUser.sid && currentUser.admin ? children : <Redirect to='/' />
          }
      />
  )
}


const BaseRouter = () => {
  return (
    <Router basename="/">
      <AnimatedRoutes>
        <Route exact path="/" component={Home} />

        <AdminRoute exact path='/upload'>
          <Route exact path="/upload" component={Upload} />
        </AdminRoute>

        <PrivateRoute exact path='/standards/:board/:version'>
          <Route component={Standards} />
        </PrivateRoute>

        <PrivateRoute exact path='/search'>
          <Route component={Search} />
        </PrivateRoute>

        {/* <Route component={NotFound} /> */}
      </AnimatedRoutes>
    </Router>
  );
};

export default BaseRouter;
