import React from "react";
import { Link } from "react-router-dom";
import { Offline, Online } from "react-detect-offline";

const ActionBar = ({
  actionBarRef,
  actionBarOverHeader,
  showMobileTOC,
  toggleMobileTOC,
  showMobilePanel,
  toggleMobilePanel,
  showPanel,
  togglePanel,
  sectionNames,
  definitions,
  handleFontSizeToggle,
  toggleZen,
  zenMode,
  handleRefreshHandbook,
}) => {
  return (
    <div className={"action-bar blur-bg-white " + (actionBarOverHeader ? "ghost " : "")} ref={actionBarRef}>
      <div className="flex between-xs inner-container">
        <button
          className={"btn btn-secondary btn-md btn-sq first show-tablet " + (showMobilePanel ? "hide-btn" : "")}
          onClick={(e) => {
            e.preventDefault();
            toggleMobileTOC();
          }}
          data-for="action-bar-tooltips"
          data-tip={`${showMobileTOC ? "Close TOC" : "Open TOC"}`}
          style={{ left: showMobileTOC ? "260px" : "0" }}
          aria-label="action bar"
        >
          <div className={"toggle-toc " + (showMobileTOC ? "rotate180 " : "rotate0 ")}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2055 11.293C14.596 11.6835 14.596 12.3166 14.2055 12.7072L11.3771 15.5356C10.9866 15.9261 10.3534 15.9261 9.96288 15.5356C9.57235 15.1451 9.57235 14.5119 9.96288 14.1214L12.0842 12.0001L9.96288 9.87874C9.57235 9.48821 9.57235 8.85505 9.96288 8.46452C10.3534 8.074 10.9866 8.074 11.3771 8.46452L14.2055 11.293Z"
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
            </svg>
          </div>
        </button>
        {/* <Offline >You are currently offline</Offline> */}
        <div>
          <Link
            to={{ pathname: "/search", searchProps: { sectionNames, definitions } }}
            className={"btn btn-secondary btn-md search-btn " + (showMobileTOC || showMobilePanel ? "hide-btn" : "")}
            style={{ paddingLeft: "8px" }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2992 15.8847C19.4363 13.1361 19.2421 9.16184 16.7163 6.63612C13.9802 3.89994 9.54393 3.89994 6.80775 6.63612C4.07157 9.3723 4.07157 13.8085 6.80775 16.5447C9.27408 19.011 13.1217 19.2543 15.8604 17.2744L17.9501 19.364C18.3406 19.7546 18.9738 19.7546 19.3643 19.364C19.7548 18.9735 19.7548 18.3403 19.3643 17.9498L17.2992 15.8847ZM15.3021 8.05033C17.2573 10.0055 17.2573 13.1754 15.3021 15.1305C13.347 17.0856 10.1771 17.0856 8.22196 15.1305C6.26683 13.1754 6.26683 10.0055 8.22196 8.05033C10.1771 6.0952 13.347 6.0952 15.3021 8.05033Z"
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
            </svg>
            <div className="spacer-8 hide-tablet"></div>
            <span className="hide-tablet">Search</span>
          </Link>
        </div>


        <div className={"flex " + (showMobileTOC || showMobilePanel ? "hide-btn" : "")}>
          <button
            className="btn btn-secondary btn-md btn-sq"
            data-for="action-bar-font-size"
            data-tip=""
            onClick={handleFontSizeToggle}
            aria-label="show mobile panel"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.446 5.82788H14.811L10.255 17.9999H12.788L13.808 15.0929H18.466L19.486 17.9999H22.019L17.446 5.82788ZM16.647 9.92488L17.718 12.9849H14.539L15.627 9.92488L16.069 8.46288H16.205L16.647 9.92488ZM6.81804 10.1238H5.11304L2.16504 17.9998H3.80404L4.46404 16.1188H7.47804L8.13804 17.9998H9.77704L6.81804 10.1238ZM6.30104 12.7748L6.99404 14.7548H4.93704L5.64104 12.7748L5.92704 11.8288H6.01504L6.30104 12.7748Z"
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
            </svg>
          </button>

          <button
            className="btn btn-secondary btn-md btn-sq"
            data-for="action-bar-refresh"
            data-tip="Refresh"
            onClick={handleRefreshHandbook}
            aria-label="show mobile panel"
          >
            <svg width="24" height="24" fill="#ffffff" version="1.1" viewBox="0 0 489.645 489.645" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
            <g id="SVGRepo_bgCarrier" stroke-width="0"/>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
            <g id="SVGRepo_iconCarrier"> <g> 
            <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
            d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3 c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5 c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8 c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2 C414.856,432.511,548.256,314.811,460.656,132.911z"/> </g> </g>
            </svg>
            
           </button>

          <button
            className="btn btn-secondary btn-md btn-sq hide-tablet"
            onClick={toggleZen}
            data-for="action-bar-zen"
            data-tip=""
            aria-label="action bar"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d={
                  zenMode
                    ? "M14.9999 10C14.4476 10 13.9999 9.55228 13.9999 9V5C13.9999 4.44771 14.4476 4 14.9999 4C15.5522 4 15.9999 4.44772 15.9999 5L15.9999 8L18.9999 8C19.5522 8 19.9999 8.44772 19.9999 9C19.9999 9.55229 19.5522 10 18.9999 10L14.9999 10Z"
                    : "M18.9999 4C19.5522 4 19.9999 4.44772 19.9999 5V9C19.9999 9.55229 19.5522 10 18.9999 10C18.4476 10 17.9999 9.55228 17.9999 9L17.9999 6L14.9999 6C14.4476 6 13.9999 5.55228 13.9999 5C13.9999 4.44771 14.4476 4 14.9999 4H18.9999Z"
                }
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
              <path
                d={
                  zenMode
                    ? "M9.99988 15C9.99988 14.4477 9.55216 14 8.99988 14H4.99988C4.44759 14 3.99988 14.4477 3.99988 15C3.99988 15.5523 4.44759 16 4.99988 16H7.99988L7.99988 19C7.99988 19.5523 8.44759 20 8.99988 20C9.55216 20 9.99988 19.5523 9.99988 19L9.99988 15Z"
                    : "M3.99988 19C3.99988 19.5523 4.44759 20 4.99988 20H8.99988C9.55216 20 9.99988 19.5523 9.99988 19C9.99988 18.4477 9.55216 18 8.99988 18H5.99988L5.99988 15C5.99988 14.4477 5.55216 14 4.99988 14C4.44759 14 3.99988 14.4477 3.99988 15V19Z"
                }
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
              <path
                d={
                  zenMode
                    ? "M14.9999 14C14.4476 14 13.9999 14.4477 13.9999 15V19C13.9999 19.5523 14.4476 20 14.9999 20C15.5522 20 15.9999 19.5523 15.9999 19V16L18.9999 16C19.5522 16 19.9999 15.5523 19.9999 15C19.9999 14.4477 19.5522 14 18.9999 14H14.9999Z"
                    : "M18.9999 20C19.5522 20 19.9999 19.5523 19.9999 19V15C19.9999 14.4477 19.5522 14 18.9999 14C18.4476 14 17.9999 14.4477 17.9999 15L17.9999 18L14.9999 18C14.4476 18 13.9999 18.4477 13.9999 19C13.9999 19.5523 14.4476 20 14.9999 20H18.9999Z"
                }
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
              <path
                d={
                  zenMode
                    ? "M9 10C9.55228 10 10 9.55229 10 9L10 5C10 4.44772 9.55229 4 9 4C8.44772 4 8 4.44771 8 5L8 8L5 8C4.44772 8 4 8.44771 4 9C4 9.55228 4.44771 10 5 10L9 10Z"
                    : "M5 4C4.44772 4 4 4.44772 4 5L4 9C4 9.55229 4.44771 10 5 10C5.55228 10 6 9.55229 6 9L6 6L9 6C9.55229 6 10 5.55229 10 5C10 4.44772 9.55229 4 9 4H5Z"
                }
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
            </svg>
          </button>
        </div>

        <button
          className="btn btn-secondary btn-md btn-sq last hide-tablet"
          onClick={togglePanel}
          data-for="action-bar-tooltips"
          data-tip={`${showPanel ? "Close panel" : "Open panel"}`}
          aria-label="toggle panel"
        >
          <div className={"toggle-side-panel " + (showPanel ? "rotate0 " : "rotate180 ")}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2055 11.293C14.596 11.6835 14.596 12.3166 14.2055 12.7072L11.3771 15.5356C10.9866 15.9261 10.3534 15.9261 9.96288 15.5356C9.57235 15.1451 9.57235 14.5119 9.96288 14.1214L12.0842 12.0001L9.96288 9.87874C9.57235 9.48821 9.57235 8.85505 9.96288 8.46452C10.3534 8.074 10.9866 8.074 11.3771 8.46452L14.2055 11.293Z"
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
            </svg>
          </div>
        </button>

        <button
          className={"btn btn-secondary btn-md btn-sq last show-tablet " + (showMobileTOC ? "hide-btn" : "")}
          onClick={(e) => {
            e.stopPropagation();
            toggleMobilePanel();
          }}
          data-for="action-bar-tooltips"
          data-tip={`${showMobilePanel ? "Close panel" : "Open panel"}`}
          style={{ right: showMobilePanel ? "260px" : "0" }}
          aria-label="toggle panel"
        >
          <div className={"toggle-side-panel " + (showMobilePanel ? "rotate0 " : "rotate180 ")}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.2055 11.293C14.596 11.6835 14.596 12.3166 14.2055 12.7072L11.3771 15.5356C10.9866 15.9261 10.3534 15.9261 9.96288 15.5356C9.57235 15.1451 9.57235 14.5119 9.96288 14.1214L12.0842 12.0001L9.96288 9.87874C9.57235 9.48821 9.57235 8.85505 9.96288 8.46452C10.3534 8.074 10.9866 8.074 11.3771 8.46452L14.2055 11.293Z"
                fill={actionBarOverHeader ? "var(--white)" : "var(--dk_gray)"}
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default ActionBar;
