import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Scrollspy from "react-scrollspy";
import MenuItem from "./MenuItem";
import { useWindowWidth } from "../hooks/useWindowWidth";
import ContentPlaceholder from "./ContentPlaceholder";

const TOC = ({
  isLoading,
  currentBoard,
  currentVersion,
  currentStandard,
  boardVersions,
  TOCRef,
  scrollSpyItems,
  handleBoardNavUpdate,
  handleVersionNavUpdate,
  showMobileTOC,
  getCurrentSection,
  headroomRef,
  currentSectionId,
}) => {
  const width = useWindowWidth();

  return (
    <aside
      className={"nav-toc " + (showMobileTOC ? "toc-open-mobile " : "toc-closed-mobile ")}
      aria-label="standard boards"
    >
      <div className="standard-nav blur-bg-white">
        <div className="nav-tabs">
          <div className="select-dropdown flex-2">
            <select
              value={currentBoard}
              onClick={() => ReactTooltip.hide()}
              onChange={handleBoardNavUpdate}
              className="nav-tab primary sm"
              name="standard-boards"
              id="standardBoards"
              data-for="board-nav"
              data-tip="Change board"
              aria-label="standard boards"
            >
              <option value="iaasb">IAASB</option>
              <option value="iesba">IESBA</option>
              <option value="ipsasb">IPSASB</option>
              {/* <option value="ies">IES</option> */}
            </select>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7055 14.2055C12.315 14.596 11.6818 14.596 11.2913 14.2055L8.46288 11.377C8.07235 10.9865 8.07235 10.3533 8.46288 9.96282C8.8534 9.57229 9.48657 9.57229 9.87709 9.96282L11.9984 12.0841L14.1197 9.96282C14.5103 9.57229 15.1434 9.57229 15.5339 9.96282C15.9245 10.3533 15.9245 10.9865 15.5339 11.377L12.7055 14.2055Z"
                fill="var(--white)"
              />
            </svg>
          </div>

          <div className="select-dropdown">
            <select
              value={currentVersion}
              onClick={() => ReactTooltip.hide()}
              onChange={handleVersionNavUpdate}
              className="nav-tab primary sm"
              name="standard-version"
              id="standardVersion"
              data-for="board-nav"
              data-tip="Change version"
              aria-label="change version"
            >
              {boardVersions &&
                boardVersions[currentBoard].map((version, i) => (
                  <option key={version + i} value={version}>
                    {version}
                  </option>
                ))}
            </select>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7055 14.2055C12.315 14.596 11.6818 14.596 11.2913 14.2055L8.46288 11.377C8.07235 10.9865 8.07235 10.3533 8.46288 9.96282C8.8534 9.57229 9.48657 9.57229 9.87709 9.96282L11.9984 12.0841L14.1197 9.96282C14.5103 9.57229 15.1434 9.57229 15.5339 9.96282C15.9245 10.3533 15.9245 10.9865 15.5339 11.377L12.7055 14.2055Z"
                fill="var(--white)"
              />
            </svg>
          </div>
        </div>
      </div>

      <ReactTooltip className="tooltip-sm" id="board-nav" place="bottom" disable={width <= 992} />

      <div className="toc-list" ref={TOCRef}>
        {isLoading && (
          <div style={{ marginLeft: 24 }}>
            <div className="spacer-16" />
            <ContentPlaceholder type="toc" />
          </div>
        )}
        {!isLoading && currentStandard !== null && (
          <Scrollspy
            componentTag="div"
            rootEl=".main-content"
            items={scrollSpyItems}
            onUpdate={(e) => {
              console.log("On Update", e);
            }}
            onEvent={(e) => {
              console.log("On Event", e);
            }}
            offEvent={(e) => {
              console.log("Off Event", e);
            }}
            currentClassName="active"
          >
            {currentStandard !== null &&
              currentStandard.toc &&
              currentStandard.toc[0].map((item) => (
                <MenuItem
                  key={item.id + item.name}
                  item={item}
                  currentStandard={currentStandard}
                  getCurrentSection={getCurrentSection}
                  headroomRef={headroomRef}
                  currentSectionId={currentSectionId}
                />
              ))}
          </Scrollspy>
        )}
      </div>

      <div className="footer-options blur-bg-white">
        {currentBoard && currentBoard ==="iesba" && (
          <a href="https://www.ethicsboard.org/standards-pronouncements" target="_blank" rel="noopener noreferrer">
            Get PDF
          </a>
        )}
        {currentBoard && currentBoard ==="iaasb" && (
          <a href="https://www.iaasb.org/standards-pronouncements" target="_blank" rel="noopener noreferrer">
            Get PDF
          </a>
        )}
        {currentBoard && currentBoard ==="ipsasb" && (
          <a href="https://www.ipsasb.org/standards-pronouncements" target="_blank" rel="noopener noreferrer">
            Get PDF
          </a>
        )}

        <a href="https://www.ifac.org/permissions-information" target="_blank" rel="noopener noreferrer">
          Permissions policy
        </a>
        {/* {currentBoard && currentBoard ==="iesba" && (
          <a href="https://www.ethicsboard.org/contact-iesba" target="_blank" rel="noopener noreferrer">
          Contact&nbsp;<span style={{ textTransform: "uppercase" }}>{currentBoard}</span>
        </a>
        )}
        {currentBoard && currentBoard ==="iaasb" && (
          <a href="https://www.iaasb.org/contact-iaasb" target="_blank" rel="noopener noreferrer">
          Contact&nbsp;<span style={{ textTransform: "uppercase" }}>{currentBoard}</span>
        </a>
        )}
        {currentBoard && currentBoard ==="ipsasb" && (
          <a href="https://www.ipsasb.org/contact-ipsasb" target="_blank" rel="noopener noreferrer">
          Contact&nbsp;<span style={{ textTransform: "uppercase" }}>{currentBoard}</span>
        </a>
        )} */}
        <a href="https://www.ifac.org/contact-eis-e-international-standards" target="_blank" rel="noopener noreferrer">
          Contact us
        </a>
        <a href="mailto:eis@ifac.org" target="_blank" rel="noopener noreferrer">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#727780" width="17" height="17" viewBox="0 0 16 16">
            <g id="SVGRepo_bgCarrier" stroke-width="0" />
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
            <g id="SVGRepo_iconCarrier">
              <path d="M15 2.5H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1zm-1.3 1.25L8.42 8.56a.62.62 0 0 1-.84 0L2.3 3.75zm-12.45 8.5V4.48l5.49 5a1.86 1.86 0 0 0 2.52 0l5.49-5v7.77z" />
            </g>
          </svg>
         &nbsp;Contact Us
        </a>
        <a href="https://www.ifac.org/eis-faqs" target="_blank" rel="noopener noreferrer">
          FAQs
        </a>
        <Link to="/" className="go-back">
          <span className="line"></span> Back to home
        </Link>
      </div>
    </aside>
  );
};

export default TOC;
