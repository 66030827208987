import { fs } from '../../firebase'

class Util { 
      static checkOnlineStatus() {        
        return new Promise(function (resolve, reject) {
          let isOnline=false;
          const docStorageRef = fs.ref(
            "standards-versions-effectivedate.json"
          );
          docStorageRef.getDownloadURL().then((url) => {
            if(url.length>0)  
            {                  
              isOnline= true;                      
            } 
            resolve(isOnline);
          })
            .catch((error) => {
              reject(isOnline);                          
            });
        });
      };
 };

export default Util;