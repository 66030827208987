import React, { useState } from "react";
import { motion } from "framer-motion";

const MenuItem = (props) => {
  const [menuExpanded, setMenuExpanded] = useState(null);
  const { item, currentStandard, getCurrentSection, headroomRef, currentSectionId } = props;
  const isOpen = item?.id === menuExpanded;
  const [hovered, setHovered] = useState(false);

  let menuItem;

  if (item?.children.length === 0) {
    menuItem = (
      <a
        key={item.id + item.name}
        href={item.key}
        onClick={() => {
          console.log(item?.containerId);
          getCurrentSection(currentStandard !== null && currentStandard, item?.containerId);
        }}
      >
        <span>{item.name}</span>
      </a>
    );
  } else {
    const handleItemClick = (e) => {
      setMenuExpanded(isOpen ? false : item?.id);
      getCurrentSection(currentStandard !== null && currentStandard, item?.containerId);
    };

    const handleItemHoverStart = () => {
      setHovered(!hovered);
    };

    const handleItemHoverEnd = () => {
      setHovered(!hovered);
    };

    menuItem = (
      <div key={item?.id + item?.name}>
        <motion.a
          href={item?.key}
          onClick={handleItemClick}
          initial={false}
          onHoverStart={handleItemHoverStart}
          onHoverEnd={handleItemHoverEnd}
          style={{ cursor: "pointer" }}
          className={`accordion-toggle ${isOpen ? "active" : ""}`}
        >
          <svg
            style={{
              transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
              marginTop: isOpen ? 1 : 0,
              transition: "var(--med-transition)",
            }}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.8 13.9333L15.3 10.6C15.7944 9.94076 15.324 9 14.5 9L9.49998 9C8.67594 9 8.20556 9.94076 8.69998 10.6L11.2 13.9333C11.6 14.4667 12.4 14.4667 12.8 13.9333Z"
              fill={hovered ? "var(--off_black)" : "var(--md_gray)"}
            />
          </svg>
          <span>{item?.name}</span>
        </motion.a>

        <motion.div
          className="accordion-content"
          key="content"
          animate={{ height: isOpen ? "100%" : "0" }}
          transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
          hidden={{ height: 0 }}
        >
          <div className="accordion-content-inner">
            {item?.children.map((child) => (
              <MenuItem
                key={child.id}
                item={child}
                currentStandard={currentStandard}
                getCurrentSection={getCurrentSection}
                headroomRef={headroomRef}
                currentSectionId={currentSectionId}
              />
            ))}
          </div>
        </motion.div>
      </div>
    );
  }

  return menuItem;
};

export default MenuItem;
