import React, { useState, useEffect } from "react";
import faviconMain from "../assets/favicon.ico";
import Logo from "../assets/eis_logo-on-blue.jpg";
import WordDoc from "../assets/word-doc.svg";
import ExcelDoc from "../assets/excel-doc.svg";
import DotsVertWhite from "../assets/images/dots-vert-white.png";
import DotsHorzWhite from "../assets/images/dots-horz-white.png";
import ReactTooltip from "react-tooltip";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStoreState } from "easy-peasy";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import DatePicker from "react-date-picker";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Confetti from "react-confetti";
import { RouteTransitionWrapper, BookLoader } from "../components";
import { useWindowSize } from "../hooks";

const alertSVG = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 4.83333C7 4.3731 7.44772 4 8 4C8.55228 4 9 4.3731 9 4.83333V8.16667C9 8.6269 8.55228 9 8 9C7.44772 9 7 8.6269 7 8.16667V4.83333Z" fill="var(--red)"/>
    <path d="M7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11Z" fill="var(--red)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z" fill="var(--red)"/>
  </svg>`;

const Upload = (props) => {
  const history = useHistory();
  const [board, setBoard] = useState(null);
  const [versionLabel, setVersionLabel] = useState(null);
  const [standardFile, setStandardFile] = useState(null);
  const [definitionsFile, setDefinitionsFile] = useState(null);

  const [standardPublishing, setStandardPublishing] = useState(false);
  const [standardEnqueued, setStandardEnqueued] = useState(false);

  const [ingestionError, setIngestionError] = useState();

  const currentUser = useStoreState((state) => state.user);

  const { width, height } = useWindowSize();
  const [isValid, setValid] = useState(false);

  const schema = yup.object().shape({
    versionLabel: yup.string().required("Version is required"),
    standardFile: yup
      .mixed()
      // .required("A Standard is required")
      .test("fileFormat", "The file must be .docx", (value) => {
        if (value.length === 0) return;
        const isDocx = value[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        if (!isDocx) return;
        setStandardFile(value[0]);
        return isDocx;
      }),
    definitionsFile: yup
      .mixed()
      // .required("Definitions are required")
      .test("fileFormat", "The file must be .xlsx", (value) => {
        if (value.length === 0) return;
        const isXlsx = value[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        if (!isXlsx) return;
        setDefinitionsFile(value[0]);
        return isXlsx;
      }),
    createdOn: yup.date().default(() => new Date()),
    effectiveDate: yup.date().required("Effective Date is required"),
  });

  const { register, control, handleSubmit, reset, watch, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const haseffectivedate = true;

  useEffect(() => {
    const favicon = document.querySelector("#favicon");
    favicon.href = faviconMain;

    if (currentUser.loggedIn === false || currentUser.admin === false) history.push("/");
    currentUser && currentUser.admin && setBoard(currentUser.board);
  }, []);

  const SuccessMsg = () => (
    <>
      <h6>
        <span style={{ textTransform: "uppercase" }}>{board}</span>
        {versionLabel} Standard
      </h6>
      <p className="lead-xs c-white op-80">Has been successfully queued for publishing process!</p>
    </>
  );

  const handlePublishStandard = (data) => {
    const bearerToken = process.env.REACT_APP_GCP_BEARER_TOKEN;
    const version = data.versionLabel.replace(/\s+/g, "-").toLowerCase();
    const createdon = data.createdOn;
    const effectivedate = data.effectiveDate ? data.effectiveDate : null;
    const standardFile = data.standardFile[0];
    const definitionsFile = data.definitionsFile[0];
    const standardDocName = standardFile.name;
    const definitionsDocName = definitionsFile.name;
    const docname = `${standardDocName}`;
    const glossaryname = `${definitionsDocName}`;
    const payload = {
      version,
      board,
      createdon,
      haseffectivedate,
      effectivedate,
      docname,
      glossaryname,
    };

    let boardName = board;
    let docName = docname;
    let glossaryName = glossaryname;
    let versionNumber = version;
    let userIdentifier = currentUser.uid;
    const payloadForGetUploadURL = {
      boardName,
      docName,
      glossaryName,
      userIdentifier,
      versionNumber,
    };

    setVersionLabel(version);
    
    let docUrl = "";
    let glossaryUrl = "";
      function getUploadUrlTaskPromise() {        
        return new Promise(function (resolve, reject) {
           setValid(true);
            fetch(process.env.REACT_APP_GET_DOCUMENT_UPLOAD_URL, {
              method: "post",
              headers: {
                accept: "application/json",
                authorization: "Bearer " + bearerToken,
                "content-type": "application/json",
              },
              body: JSON.stringify(payloadForGetUploadURL),
              mode: "cors",
            })
            .then((res) => res.json())
            .then((urls) => {
            docUrl = urls.docUrl;
            glossaryUrl = urls.glossaryUrl;
            resolve();
            setValid(false);
            
          })
            .catch(() => {
              reject();
              setValid(false);
            });
        });
      };

    getUploadUrlTaskPromise().then(() => {
      let promises = [];
      function getUploadDocTaskPromise() {
        return new Promise(function (resolve, reject) {
            fetch(docUrl, {
              method: "put",
              headers: {
                "content-type": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              },
              body: standardFile,
              //mode: "cors",
            })
            .then(() => {
            resolve();
          })
            .catch(() => {
              reject();
            });
        });
      };

      promises.push(getUploadDocTaskPromise());
      
      function getUploadGlossaryTaskPromise() {
        return new Promise(function (resolve, reject) {
            fetch(glossaryUrl, {
              method: "put",
              headers: {
                "content-type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              },
              body: definitionsFile,
              //mode: "cors",
            })
            .then(() => {
            resolve();
          })
            .catch(() => {
              reject();
            });
        });
      };

      promises.push(getUploadGlossaryTaskPromise());

      Promise.all(promises).then(async () => {
        const url = process.env.REACT_APP_UPLOAD_DOCUMENTS;
        
        setStandardPublishing(true);

        await fetch(url, {
          method: "post",
          headers: {
            accept: "application/json",
            authorization: "Bearer " + bearerToken,
            "content-type": "application/json",
          },
          body: JSON.stringify(payload),
          mode: "cors",
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res);

            if (res.response === "200") {
              setStandardEnqueued(true);

              toast.success(SuccessMsg, {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            } else if (res.response === "401") {
              setIngestionError({ type: "Unauthorized", message: "You don‘t have the proper permission." });
            } else if (res.response === "404") {
              setIngestionError({ type: res.type, message: res.message });
            } else if (res.response === "500") {
              setIngestionError({ type: "Server", message: "A server error was encountered." });
            }
          });
      });
    });
  
  };

  return (
    <RouteTransitionWrapper amount={96}>
      <div
        className="flex-full-height"
        style={{ color: "var(--white)", backgroundColor: "var(--brand)", overflow: "auto" }}
      >
        <header className="header space-between">
          <div className="flex align-center">
            <a href="/" className="logo">
              <img src={Logo} alt="IFAC" />
            </a>
          </div>

          <button
            onClick={() => props.history.goBack()}
            className="btn btn-ghost btn-circle btn-circle-md"
            data-for="close"
            data-tip="Close"
            aria-label="close"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.2427 9.17146C16.6332 8.78094 16.6332 8.14777 16.2427 7.75725C15.8521 7.36672 15.219 7.36672 14.8284 7.75725L11.9999 10.5858L9.17158 7.7574C8.78106 7.36688 8.1479 7.36688 7.75737 7.7574C7.36685 8.14792 7.36685 8.78109 7.75737 9.17161L10.5857 12L7.75738 14.8283C7.36686 15.2188 7.36685 15.852 7.75738 16.2425C8.1479 16.6331 8.78107 16.6331 9.17159 16.2425L11.9999 13.4142L14.8284 16.2427C15.219 16.6332 15.8521 16.6332 16.2427 16.2427C16.6332 15.8522 16.6332 15.219 16.2427 14.8285L13.4142 12L16.2427 9.17146Z"
                fill="var(--white)"
              />
            </svg>
          </button>
        </header>

        <ReactTooltip className="tooltip-sm" id="close" place="bottom" disable={width <= 992} />

        <div className="spacer-64" />

        <div className="pos-rel h-full">
          <img className="dots left show-lg" src={DotsVertWhite} alt="" />

          <div className="container pos-rel h-full">
            <img className="dots bottom show-lg" style={{ right: "24px" }} src={DotsHorzWhite} alt="" />

            <div className="show-tablet" style={{ justifyContent: "center" }}>
              <div className="info-well">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 8C11.4477 8 11 8.44771 11 9V12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12V9C13 8.44772 12.5523 8 12 8Z"
                    fill="var(--white)"
                  />
                  <path
                    d="M12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14Z"
                    fill="var(--white)"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20 12C20 16.4183 16.4183 20 12 20C7.58173 20 4 16.4183 4 12C4 7.58172 7.58173 4 12 4C16.4183 4 20 7.58172 20 12ZM18 12C18 15.3137 15.3137 18 12 18C8.68628 18 6 15.3137 6 12C6 8.68629 8.68628 6 12 6C15.3137 6 18 8.68629 18 12Z"
                    fill="var(--white)"
                  />
                </svg>

                <div style={{ flex: 1 }}>
                  <p className="c-white">Sorry, but you cannot upload using a phone or tablet.</p>
                  <div className="spacer-4"></div>
                  <p className="c-white">Please upload standard on a laptop or desktop.</p>
                </div>
              </div>
            </div>

            <div className="hide-tablet">
              {!standardPublishing && (
                <motion.div
                  className="row"
                  initial={{
                    opacity: 0,
                    y: 48,
                  }}
                  exit={{
                    opacity: 0,
                    y: 48,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                >
                  <div className="col-xs-12 col-md-4 col-lg-5">
                    <h1>
                      <span style={{ textTransform: "uppercase" }}>{board}</span>
                      <br />
                      Standard
                      <br />
                      Upload
                    </h1>

                    <div className="spacer-48" />

                    <p className="lead-lg c-white op-80">
                      Upload the appropriate documents, provide a version label, and set the effective date.
                    </p>

                    <div className="spacer-24" />

                    <p className="op-65">
                      When the standard has been successfully uploaded, you will receive a notification and can view it
                      online.
                    </p>

                    <div className="spacer-48" />
                  </div>

                  <div className="col-md-1 hide-xs show-sm" />

                  <div className="spacer-48 hide-md show-sm" />

                  <div className="col-xs-12 col-md-7 col-lg-6">
                    <form
                      className="h-full"
                      onSubmit={handleSubmit(handlePublishStandard)}
                      autoComplete="off"
                      noValidate
                    >
                      <div>
                        <div className="flex space-between align-center">
                          <h4>Standard</h4>
                          <div className="flex align-center">
                            <label htmlFor="versionLabel" className="c-white">
                              Version
                            </label>
                            <div className="spacer-8" />
                            <input
                              ref={register}
                              type="text"
                              name="versionLabel"
                              id="versionLabel"
                              className={`form-input sm ghost ${errors.versionLabel && "has-error"}`}
                              placeholder="e.g. 2020, Jan 2020..."
                              required
                            />
                          </div>
                        </div>
                        {errors.versionLabel && (
                          <div className="flex flex-column align-bottom">
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{ __html: alertSVG + errors.versionLabel.message }}
                            />
                          </div>
                        )}

                        <div className="spacer-12" />

                        <div
                          className={`file-upload ${standardFile && "has-file"} ${errors.standardFile && "has-error"}`}
                        >
                          <div className="flex align-center">
                            <img src={WordDoc} alt="" />
                            <div className="spacer-24" />
                            <div>
                              <AnimatePresence exitBeforeEnter>
                                {standardFile ? (
                                  <motion.div
                                    initial={{
                                      opacity: 0,
                                      y: 0,
                                    }}
                                    exit={{
                                      opacity: 0,
                                      y: 24,
                                    }}
                                    animate={{
                                      opacity: 1,
                                      y: 0,
                                    }}
                                    transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                                  >
                                    <h6>{standardFile.name}</h6>
                                  </motion.div>
                                ) : (
                                  <motion.div
                                    initial={{
                                      opacity: 0,
                                      y: 0,
                                    }}
                                    exit={{
                                      opacity: 0,
                                      y: 24,
                                    }}
                                    animate={{
                                      opacity: 1,
                                      y: 0,
                                    }}
                                    transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                                  >
                                    <h6>Drag or select word document</h6>
                                    <div className="spacer-4" />
                                    <p className="xs op-80">.docx only</p>
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                          </div>
                          <input
                            ref={register}
                            className="full"
                            type="file"
                            id="standardFile"
                            name="standardFile"
                            required
                          />
                        </div>
                        {errors.standardFile && (
                          <div className="flex flex-column align-bottom">
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{ __html: alertSVG + errors.standardFile.message }}
                            />
                          </div>
                        )}
                        {standardFile && (
                          <div className="flex flex-column align-bottom">
                            <div className="spacer-12" />
                            <button
                              onClick={() => {
                                setStandardFile(null);
                                reset({ standardFile: null });
                              }}
                              className="btn btn-sm btn-ghost"
                              aria-label="set standard file"
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="spacer-32" />

                      <div>
                        <div className="flex align-center">
                          <h4>Definitions</h4>
                        </div>
                        <div className="spacer-12" />
                        <div
                          className={`file-upload ${definitionsFile && "has-file"} ${errors.definitionsFile && "has-error"
                            }`}
                        >
                          <div className="flex align-center">
                            <img src={ExcelDoc} alt="" />
                            <div className="spacer-24" />
                            <div>
                              <AnimatePresence exitBeforeEnter>
                                {definitionsFile ? (
                                  <motion.div
                                    initial={{
                                      opacity: 0,
                                      y: 0,
                                    }}
                                    exit={{
                                      opacity: 0,
                                      y: 24,
                                    }}
                                    animate={{
                                      opacity: 1,
                                      y: 0,
                                    }}
                                    transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                                  >
                                    <h6>{definitionsFile.name}</h6>
                                  </motion.div>
                                ) : (
                                  <motion.div
                                    initial={{
                                      opacity: 0,
                                      y: 0,
                                    }}
                                    exit={{
                                      opacity: 0,
                                      y: 24,
                                    }}
                                    animate={{
                                      opacity: 1,
                                      y: 0,
                                    }}
                                    transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                                  >
                                    <h6>Drag or select excel document</h6>
                                    <div className="spacer-4" />
                                    <p className="xs op-80">.xlsx only</p>
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                          </div>
                          <input
                            ref={register}
                            className="full"
                            type="file"
                            id="definitionsFile"
                            name="definitionsFile"
                            required
                          />
                        </div>
                        {errors.definitionsFile && (
                          <div className="flex flex-column align-bottom">
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{ __html: alertSVG + errors.definitionsFile.message }}
                            />
                          </div>
                        )}
                        {definitionsFile && (
                          <div className="flex flex-column align-bottom">
                            <div className="spacer-12" />
                            <button
                              onClick={() => {
                                setDefinitionsFile(null);
                                reset({ definitionsFile: null });
                              }}
                              className="btn btn-sm btn-ghost"
                              aria-label="remove"
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="spacer-32" />

                      <div className="flex space-between align-center">
                        <div >
                          {/* <input id="hasEffectiveDate" type="checkbox" name="hasEffectiveDate" ref={register} /> */}
                          <label tabIndex="0">
                            Set an effective date 
                            {/* <span className="op-65"></span> */}
                          </label>
                        </div>

                        <AnimatePresence exitBeforeEnter>
                          {(
                            <motion.div
                              initial={{
                                opacity: 0,
                                y: 24,
                              }}
                              exit={{
                                opacity: 0,
                                y: 24,
                              }}
                              animate={{
                                opacity: 1,
                                y: 0,
                              }}
                              transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                            >
                              <Controller
                                control={control}
                                name="effectiveDate"
                                required
                                render={({ onChange, value, name }) => (
                                  <div className="ifac-date-picker ghost">
                                    <DatePicker
                                      onChange={onChange}
                                      value={value}
                                      name={name}
                                      minDate={new Date()}
                                      maxDetail="month"
                                    />
                                  </div>
                                )}
                              />
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                      {errors.effectiveDate && (
                          <div className="flex flex-column align-bottom">
                            <div
                              className="error"
                              dangerouslySetInnerHTML={{ __html: alertSVG + errors.effectiveDate.message }}
                            />
                          </div>
                        )}
                      <div className="spacer-48" />

                      <div className="flex flex-column align-bottom">
                        <button className="btn btn-blue" disabled={isValid} type="submit" aria-label="submit">
                        {isValid ? (<>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ height: "48px", width: "159.104px" }}>
                    <rect x="17.5" y="30" width="15" height="40" fill="#ffffff">
                      <animate attributeName="y" repeatCount="indefinite" dur="1.0309278350515465s" calcMode="spline" keyTimes="0;0.5;1" values="18;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2061855670103093s"></animate>
                      <animate attributeName="height" repeatCount="indefinite" dur="1.0309278350515465s" calcMode="spline" keyTimes="0;0.5;1" values="64;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.2061855670103093s"></animate>
                    </rect>
                    <rect x="42.5" y="30" width="15" height="40" fill="#f8f8f8">
                      <animate attributeName="y" repeatCount="indefinite" dur="1.0309278350515465s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.10309278350515465s"></animate>
                      <animate attributeName="height" repeatCount="indefinite" dur="1.0309278350515465s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.10309278350515465s"></animate>
                    </rect>
                    <rect x="67.5" y="30" width="15" height="40" fill="#f0f0f0">
                      <animate attributeName="y" repeatCount="indefinite" dur="1.0309278350515465s" calcMode="spline" keyTimes="0;0.5;1" values="20.999999999999996;30;30" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                      <animate attributeName="height" repeatCount="indefinite" dur="1.0309278350515465s" calcMode="spline" keyTimes="0;0.5;1" values="58.00000000000001;40;40" keySplines="0 0.5 0.5 1;0 0.5 0.5 1"></animate>
                    </rect>
                  </svg></>) : <>

                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.2924 3.46452C11.6829 3.074 12.3161 3.074 12.7066 3.46452L15.535 6.29295C15.9256 6.68348 15.9256 7.31664 15.535 7.70716C15.1445 8.09769 14.5114 8.09769 14.1208 7.70716L13 6.58633L13 15.0001C13 15.5524 12.5523 16.0001 12 16.0001C11.4477 16.0001 11 15.5524 11 15.0001L11 6.58536L9.87819 7.70716C9.48767 8.09769 8.8545 8.09769 8.46398 7.70716C8.07345 7.31664 8.07345 6.68348 8.46398 6.29295L11.2924 3.46452Z"
                              fill="var(--white)"
                            />
                            <path
                              d="M6 13.0001C6 12.4478 5.55228 12.0001 5 12.0001C4.44772 12.0001 4 12.4478 4 13.0001V17.0001C4 18.6569 5.34315 20.0001 7 20.0001H17C18.6569 20.0001 20 18.6569 20 17.0001V13.0001C20 12.4478 19.5523 12.0001 19 12.0001C18.4477 12.0001 18 12.4478 18 13.0001V17.0001C18 17.5524 17.5523 18.0001 17 18.0001H7C6.44772 18.0001 6 17.5524 6 17.0001V13.0001Z"
                              fill="var(--white)"
                            />
                          </svg>
                          <div className="spacer-12" />
                          Publish standard
                          </> 
                          }
                        </button>
                      </div>

                      <div className="spacer-48" />
                    </form>
                  </div>
                </motion.div>
              )}

              {standardPublishing && (
                <motion.div
                  className="row"
                  initial={{
                    opacity: 0,
                    y: 48,
                  }}
                  exit={{
                    opacity: 0,
                    y: 48,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{ duration: 0.8, ease: [0.6, 0.05, -0.01, 0.9] }}
                >
                  <div className="flex flex-1 flex-column justify-center align-center">
                    <div className="spacer-144" />
                    {!standardEnqueued && (
                      <>
                        <h3>
                          Publishing <span style={{ textTransform: "uppercase" }}>{board}</span> {versionLabel}{" "}
                          standard...
                        </h3>
                        <div className="spacer-96" />
                        <BookLoader />
                      </>
                    )}

                    {!standardEnqueued && ingestionError && (
                      <>
                        <h3>
                          <span style={{ textTransform: "uppercase" }}>{board}</span> {versionLabel} standard was not
                          published.
                        </h3>
                        <div className="spacer-24" />
                        <p className="lead">See error below</p>
                        <div className="spacer-64" />

                        <div className="error-item">
                          <span style={{ textTransform: "capitalize" }} className="error-type">
                            {ingestionError.type}
                          </span>
                          <span style={{ textTransform: "capitalize" }} className="error-message">
                            {ingestionError.message}
                          </span>
                        </div>

                        <div className="spacer-64" />

                        <button onClick={() => window.location.reload()} aria-label="try again">Try again</button>
                      </>
                    )}

                    {standardEnqueued && (
                      <>
                        <h3>
                          <span style={{ textTransform: "uppercase" }}>{board}</span> {versionLabel} standard is queued for processing!
                        </h3>
                        <div className="spacer-64" />
                        {/* <Link to={`/standards/${board}/${versionLabel}`} className="btn btn-blue">
                          View standard{" "}
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.1213 8.46446C11.5118 8.07394 12.145 8.07394 12.5355 8.46446L15.3639 11.2929C15.7544 11.6834 15.7544 12.3166 15.3639 12.7071L12.5355 15.5355C12.145 15.9261 11.5118 15.9261 11.1213 15.5355C10.7307 15.145 10.7307 14.5118 11.1213 14.1213L13.2426 12L11.1213 9.87868C10.7307 9.48815 10.7307 8.85499 11.1213 8.46446Z"
                              fill="white"
                            />
                          </svg>
                        </Link> */}
                      </>
                    )}
                  </div>
                </motion.div>
              )}
              {/* </AnimatePresence> */}
            </div>
          </div>
        </div>
      </div>

      {standardEnqueued && <Confetti width={width} height={height} wind={0.02} />}
      <ToastContainer transition={Slide} className="toast-container" duration={6500} />
    </RouteTransitionWrapper>
  );
};

export default Upload;
